.b-minicart {
	@include g-sliding_panel;

	&-inner {
		padding: rh(8 5);

		.b-minicart[aria-busy='true'] & {
			opacity: 0.6;
			transition: opacity $motion-ease;
		}
	}

	&-header {
		margin-bottom: rh(5);
	}

	&-messages_item {
		padding: rh(1 2);
	}

	&-content {
		margin: rh(8 0 0);
	}

	&-title {
		@include g-heading_3;
	}

	&-actions {
		display: flex;
		justify-content: space-between;

		.b-button {
			padding-left: 10px;
			padding-right: 10px;
			width: 100%;
		}

		.b-button + .b-button {
			margin-inline-start: rh(2);
		}
	}

	&-empty {
		@include g-heading_3;

		align-items: center;
		display: flex;
		flex-direction: column;
		margin-top: 20%;
		text-align: center;
	}

	&-empty_icon {
		@include g-icon_gradient;

		margin-bottom: rh(8);
	}

	.return_refunds {
		border-bottom: 1px solid black;
		background-color: $color-bg-utility-bar;
		padding: rh(2 5);
		max-height: 35px;
		text-align: center;
	}
}
