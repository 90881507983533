.b-footer_social {
	@include media(md-up) {
		flex-basis: grid-span(4, 'lg');
	}

	.b-footer_nav-title {
		@include media(sm) {
			font-size: 18px;
			font-weight: 400;
			letter-spacing: 1px;
			line-height: 1.33;
			margin-bottom: 17px;
			text-align: center;
			text-transform: uppercase;
			display: block;
		}
	}

	&-items {
		display: flex;
		margin-top: -7px;

		@include media(sm) {
			justify-content: center;
			margin-top: 0;
		}
	}

	&-link {
		@include g-button_iconed;

		height: 20px;
		margin-right: 22px;
		width: 20px;

		@include media(sm) {
			height: 28px;
			margin-right: 30px;
			width: 28px;
		}

		&:hover {
			opacity: 0.7;
			transition: all 0.3s ease-in-out;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	svg {
		fill: currentColor;

		@include media(sm) {
			height: 28px;
			width: 28px;
		}
	}
}
