.b-copyright {
	font-size: 14px;
	padding: 34px 0 30px;
	text-align: left;
	width: 100%;

	@include media(sm) {
		margin: 0 auto;
		max-width: 280px;
		padding: 30px 0 20px;
		text-align: center;
	}

	&.m-centered {
		padding: 24px 0 20px;
		text-align: center;
	}

	&-link {
		@include media(md-up) {
			margin-inline-start: rh(4);
		}

		@include media(sm) {
			margin-inline-end: rh(4);
		}
	}
}
