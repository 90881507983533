/*md

# b-checkbox

Please see [g-checkbox](02-components-g-checkbox.html) for details.

*/

.b-checkbox {
	@include g-checkbox;

	&-input {
		@include g-checkbox(_input);
	}

	&-icon {
		@include g-checkbox(_icon);

		background: transparent;
		height: 24px;
		margin-top: 1px;
		padding: 3px;
		width: 24px;

		.b-checkbox-input:active + & {
			@include g-checkbox(_icon, m-active);
		}

		.b-checkbox:hover & {
			@include g-checkbox(_icon, m-hover);
		}

		.b-checkbox-input:checked + & {
			@include g-checkbox(_icon, m-checked);

			border: 1px solid $color-black;
		}

		.b-checkbox-input[disabled] + & {
			@include g-checkbox(_icon, m-disabled);
		}

		.b-checkbox-input.m-invalid + & {
			@include g-checkbox(_icon, m-invalid);
		}
	}

	&-label {
		cursor: pointer;

		.b-checkbox-input[disabled] + .b-checkbox-icon + & {
			color: $color-action-disabled;
			cursor: default;
		}
	}
}
