.b-cancellation_popup_footer {
	border-top: 1px solid $color-divider-light;
	padding-top: 20px;

	&-actions {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
	}

	&-button {
		flex-basis: calc(50% - 5px);
	}
}
