.b-suggestions {
	background-color: $color-bg;
	overflow-y: auto;
	padding: 30px 0;
	position: relative;
	right: 0;
	width: 100%;
	z-index: z(components, search, suggestions);

	@include media(lg-up) {
		padding: rh(10 0);
	}

	// Layout
	&-inner {
		@include g-section_holder;

		display: flex;
		flex-direction: column;
		justify-content: center;
		transition: opacity $motion-ease;

		@include media(lg-up) {
			align-items: center;
		}

		&.m-empty { // for error message
			align-items: center;
			color: $color-text-dimmed;
			display: flex;
			justify-content: center;
			min-height: 120px;
			padding: 0 rh(2);
			text-align: center;
		}

		.b-suggestions.m-loading & {
			opacity: 0.3;
			pointer-events: none;
		}
	}

	&-section {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		margin-bottom: 30px;

		@include media(lg-up) {
			margin-bottom: 40px;
			padding: 0;
			width: grid-span($column: 6);
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	// Content
	&-title {
		font-size: 15px;
		font-weight: 500;
		letter-spacing: 1px;
		margin-bottom: 15px;
		text-transform: uppercase;
	}

	&-option {
		border-radius: $global-radius;
		display: block;
		margin-bottom: rh(4);
		position: relative;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&-link {
		@include g-link;

		line-height: 1.14;
		transition: color $motion-fast;

		&.m-focused {
			color: $color-action;
		}

		// textEl underline default opacity overide
		.b-suggestions-link_text::before {
			opacity: 1;
		}
	}

	&-message {
		@include hide(visually);
	}

	&-view_results {
		font-size: 14px;
		padding: 14px 40px;

		@include media(sm) {
			width: 100%;
		}

		&.m-focused {
			background: $color-action-obj;
			color: adjust-color-to-bg($color-action-obj);
		}
	}
}
