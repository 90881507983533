.b-countdown {
	align-items: center;
	display: flex;
	justify-content: center;
	position: relative;

	&-time {
		@include g-heading_1;

		position: absolute;
	}

	&-icon {
		height: 190px;
		transform: rotateZ(-90deg);
		width: 190px;
	}

	&-circle_bg {
		fill: none;
		stroke: $color-bg-shade;
		stroke-width: 5px;
	}

	&-circle_animated {
		fill: none;
		stroke: $color-primary;
		stroke-width: 5px;
		transition: stroke-dasharray 2.8s linear;
	}
}
