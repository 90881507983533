.b-footer_headline {
	padding-top: 6px;

	@include media(sm) {
		padding-top: 0;
		text-align: center;
	}

	&-title {
		color: $color-black;
		font-size: 23px;
		font-weight: 500;
		letter-spacing: 0.7px;
		margin-bottom: 15px;
		text-transform: uppercase;
		display: block;
	}
}
