/*md

# Form section

Use this component to add some structure to forms.
It is possible to use any form element inside.

## Input element with label

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="input1">
			Enter Promo Code
		</label>
		<input class="b-input" type="text" id="input1" placeholder="Promo Code" name="couponCode" required="" value="">
	</div>
```

## Input element with required label

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="input2">
			<span class="b-form_section-required">*</span>
			Enter Promo Code
		</label>
		<input class="b-input" type="text" id="input2" placeholder="Promo Code" name="couponCode" required="" value="">
	</div>
```

## Input element with caption

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="input3">
			<span class="b-form_section-required">*</span>
			Enter Promo Code
		</label>
		<input class="b-input" type="text" id="input3" placeholder="Promo Code" name="couponCode" required="" value="">
		<span class="b-form_section-caption">
			Casing &amp; hyphens need to be exact
		</span>
	</div>
```

## Input element with error

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="input4">
			<span class="b-form_section-required">*</span>
			Enter Promo Code
		</label>
		<input class="b-input m-invalid" type="text" id="input4" placeholder="Promo Code" name="couponCode" required="" value="">
		<div role="alert" class="b-form_section-message">
			Coupon cannot be added to your cart
		</div>
		<span class="b-form_section-caption">
			Casing &amp; hyphens need to be exact
		</span>
	</div>
```

## Select element with label

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="select1">
			<span class="b-form_section-required">*</span>
			Country
		</label>
		<div class="b-select">
			<select class="b-select-input" id="select1" name="" required="" value="">
				<option value="US" data-id="US">United States</option>
				<option value="CA" data-id="CA">Canada</option>
			</select>
			<svg aria-hidden="true" class="b-select-icon" width="10" height="6" focusable="false">
				<path fill="currentColor" d="M5 6c-.256 0-.512-.098-.707-.293l-4-4c-.39-.39-.39-1.023 0-1.414.391-.39 1.023-.39 1.414 0l3.305 3.305L8.305.418c.4-.383 1.03-.372 1.414.025.384.397.373 1.031-.024 1.414l-4 3.862C5.5 5.907 5.25 6 5 6"></path>
			</svg>
		</div>
	</div>
```

## Checkbox element with label

```html_example
	<div class="b-form_section">
		<div class="b-checkbox">
			<input type="checkbox" class="b-checkbox-input" id="checkox1">
			<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
				<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
			</svg>
			<label class="b-form_section-label" for="checkox1">
				<span class="b-form_section-required">*</span>
					By signing up, you agree to <a href="#" target="_blank">Privacy and Cookie Policies</a>
			</label>
		</div>
	</div>
```

*/

.b-form_section {
	&.m-small {
		width: 250px;
	}

	&.m-highlight {
		background: $color-bg-shade;
		padding: rh(4);
	}

	&.m-fullwidth {
		width: 100%;
	}

	.b-form & {
		margin-bottom: 30px;

		&.m-no_margin {
			margin-bottom: 0;
		}

		&.m-topline {
			border-top: 1px solid $color-divider-light;
			margin-bottom: 20px;
			padding-top: 20px;
		}
	}

	&-label {
		display: flex;

		&.m-wai {
			@include hide(visually);
		}

		.b-checkbox & {
			display: block;
		}
	}

	&-required {
		.b-form_section.m-static_option & {
			display: none;
		}
	}

	&-link {
		@include g-link('underlined');

		font-weight: 500;
	}

	&-email {
		font-weight: 700;
	}

	& > &-label {
		font-size: 12px;
		font-weight: 700;
		margin-bottom: 8px;
	}

	&-caption {
		color: $color-grey46;
		display: block;
		font-size: 12px;
		font-weight: 500;
		margin-top: 5px;
	}

	&-value {
		display: block;
		font-size: 16px;
		line-height: 1;
	}

	&-counter {
		font-size: 12px;
		font-weight: 500;
		text-align: end;
	}

	&-message {
		color: $color-error;
		display: block;
		font-size: 12px;
		font-weight: 500;
		margin-top: 10px;

		&::before {
			content: '*';
		}

		&[hidden] {
			display: none;
		}
	}

	input[type="date"] {
		background-color: $color-white;
		&::-webkit-input-placeholder{
			visibility: hidden !important;
		}
		&::-webkit-datetime-edit-day-field:focus,
		&::-webkit-datetime-edit-month-field:focus,
		&::-webkit-datetime-edit-year-field:focus {
			background-color: #b4d7ff;
			outline: none;
		}
	}

	input::-webkit-calendar-picker-indicator{
		display: none;
	}
}
