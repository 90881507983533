.b-connection_monitor {
	margin-bottom: 0;
	position: relative;

	@include media(sm) {
		align-items: center;
		display: flex;
		padding: rh(3 0);
	}

	&-inner {
		@include g-section_holder;

		align-items: center;
		display: flex;
		justify-content: center;
	}

	&-icon {
		flex-shrink: 0;
	}

	&-caption {
		font-weight: 700;
	}

	&-message {
		font-weight: 400;
		margin-inline-start: rh(3);
		text-align: start;
	}

	&-btn {
		@include g-button_iconed;

		@include media(md-up) {
			position: absolute;
			right: rh(2);
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
