/*md

# b-promotion

Designed to provide same styles of promotion across different components.

We do not support any HTML in promotion messages.

## Promotion with configured link

```html_example
<div class="b-promotion">
	<div class="b-promotion-message">
		Get 15% off for <a href="#">order</a>
	</div>
</div>
```

## Empty promotion

```html_example
<div class="b-promotion">
	<div class="b-promotion-message"></div>
</div>
```

## Promotion with open dialog button

```html_example
<div class="b-promotion">
	<div class="b-promotion-message">
		Get 15% off for order
	</div>
	<button
		class="b-promotion-details"
		type="button"
		data-widget="emitBusEvent"
		data-bus-event-type="dialog.show"
		data-event-click.prevent="emitBusEvent"
		data-tau="promotion_details_cta"
		data-initialized="1"
	>
		Details
	</button>
</div>
```

*/

.b-promotion {
	align-items: center;
	display: flex;
	font-size: 12px;
	font-weight: 400;
	margin-bottom: 10px;
	position: relative;

	&-message {
		background-color: $color-bg-promo;
		border: 1px solid $color-beige;
		border-radius: 3px;
		color: $color-almost-black;
		font-size: 12px;
		padding: 3px 8px;

		&:empty { // hide promotion if no callout message
			display: none;
		}

		a {
			color: inherit;
			text-decoration: underline;
		}

		// Exception for CO Shipping methods promotions
		.b-option_switch-promotion & {
			background-color: $color-grey-5;
			border-color: $color-grey-5;
			color: $color-black;
		}
	}

	&-details {
		@include g-link;

		margin-inline-start: 10px;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}
