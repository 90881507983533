/*md

# b-highlighter

Focus highlighter CSS part. It draw focus ring over focused element.

We have two types of focus highlighting. In case if custom highlighter is not initialized will be shown default outline highlighter.

### Default outline highlighter
```html_example
<br>
<div>
	<input class="b-input m-focused" type="text" placeholder="Default outline example" style="width:240px;">
</div>
<br>
```

### Custom highlighter
```html_example
<br>
<div style="position:relative;">
	<div class="b-highlighter_inited">
		<input class="b-input" type="text" placeholder="Custom highlighter example" style="width:240px;">
	</div>
	<div class="b-highlighter m-visible" style="top:-5px; left:-4px; width:241px; height:50px;"></div>
</div>
<br>
```
*/
$highlighter-color: $color-black;

.b-highlighter {
	border: 4px solid transparent;
	border-radius: 2px;
	box-shadow: 0 0 10px 0 $color-action;
	box-sizing: content-box;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	transition-duration: 0.15s;
	transition-property: top, left, width, height, visibility, opacity;
	transition-timing-function: cubic-bezier(0, 0.2, 0, 1);
	visibility: hidden;
	z-index: z(highlighter);

	&.m-visible {
		opacity: 1;
		visibility: visible;
	}

	&.m-hurry {
		transition-duration: 0.1s;
	}
}

// stylelint-disable
*:focus,
.m-focused {
	outline: 3px solid $highlighter-color !important;
}
// stylelint-enable

.b-highlighter_inited *:focus { // stylelint-disable-line
	outline: none !important; // stylelint-disable-line
}
