.b-footer_info {
	&-inner {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@include media(sm) {
			flex-direction: column;
		}
	}
}
