/*md

# Rating

Designed to provide same styles of rating across different components.

<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
    <!-- 0 0 16.476 15.677 shape from 0.0 -->
    <symbol id="star">
        <path d="m13.344 15.662-5.0953-2.6691-5.0873 2.6842 0.96393-5.6707-4.1249-4.0089 5.691-0.83558 2.538-5.1618 2.5533 5.1543 5.6935 0.81868-4.113 4.0211z"></path>
    </symbol>
    <!-- 0 0 16.476 15.677 shape from 0.0 -->
    <symbol id="star-half">
        <path class="b-rating-empty" d="m13.344 15.662-5.0953-2.6691-5.0873 2.6842 0.96393-5.6707-4.1249-4.0089 5.691-0.83558 2.538-5.1618 2.5533 5.1543 5.6935 0.81868-4.113 4.0211z"></path>
        <path class="b-rating-filled" d="m8.1348 0.19141-2.4434 4.9707-5.6914 0.83594 4.125 4.0078-0.96484 5.6719 4.9746-2.625v-12.861z"></path>
    </symbol>
</svg>

```html_example
<div class="b-rating" title="5 out of 5 Customer Rating">
	<svg class="b-rating-icon" width="100" height="20" viewBox="0 0 100 16" focusable="false">
		<use class="b-rating-filled" href="#star" y="0" x="0.0"></use>
		<use class="b-rating-filled" href="#star" y="0" x="20.0"></use>
		<use class="b-rating-filled" href="#star" y="0" x="40.0"></use>
		<use class="b-rating-filled" href="#star" y="0" x="60.0"></use>
		<use class="b-rating-filled" href="#star" y="0" x="80.0"></use>
	</svg>
	<span class="b-rating-number" data-tau="product_rating">(5)</span>
</div>
```

## Rating with link to product

```html_example
<div class="b-rating" title="3.2 out of 5 Customer Rating">
	<a class="b-rating-link" href="#" title="3.2 out of 5 Customer Rating" aria-label="3.2 out of 5 Customer Rating">
		<svg class="b-rating-icon" width="100" height="20" viewBox="0 0 100 16" focusable="false">
			<use class="b-rating-filled" href="#star" y="0" x="0.0"></use>
			<use class="b-rating-filled" href="#star" y="0" x="20.0"></use>
			<use class="b-rating-filled" href="#star" y="0" x="40.0"></use>
			<use href="#star-half" y="0" x="60.0"></use>
			<use class="b-rating-empty" href="#star" y="0" x="80.0"></use>
		</svg>
		<span class="b-rating-number" data-tau="product_rating">(3.2)</span>
	</a>
</div>
```
*/

.b-rating {
	display: flex;

	&-link {
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}

	&-icon {}

	&-filled {
		fill: $color-yellow;
	}

	&-empty {
		fill: $color-grey73;
	}

	&-number {
		font-weight: 600;
		unicode-bidi: isolate;
	}
}
