@mixin g-sliding_panel {
	background-color: $color-bg-panel;
	bottom: 0;
	color: adjust-color-to-bg($color-bg-panel);
	height: 100%;
	max-width: 496px;
	overflow-y: auto;
	position: fixed;
	right: 0;
	top: 0;
	transform: translateX(100%);
	transition-property: transform;
	visibility: hidden;
	width: 80vw;
	z-index: z(modal);

	@include rtl {
		left: 0;
		right: initial;
		transform: translateX(-100%);
	}

	&.m-activated { // need this class to not show panel transition on page load. Transition only after user interaction
		transition: $motion-ease-popups;
	}

	&.m-active {
		box-shadow: $depth-3;
		transform: translateX(0);
		transition: $motion-ease-popups;
		visibility: visible;

		@include rtl {
			transform: translateX(0);
		}
	}

	&.m-loading_long::before {
		@include g-spinner(3em);

		left: 50%;
		margin: -1em 0 0 -1em;
		position: absolute;
		top: 50%;
	}
}
