/*md
# b-content_placeholder

Future implemented content placeholder. Needed only for not implemented features, like "Bazarvoice reviews will be here".

## Example

```html_example
<div class="b-content_placeholder">
	Bazaarvoice reviews will be here
</div>
```
*/

.b-content_placeholder {
	@include g-heading_1;

	align-items: center;
	background-color: $color-grey95;
	color: $color-text-dimmed;
	display: flex;
	height: 70vh;
	justify-content: center;
}
