/*md

# Select

This component allows user to choose one option from drop-down list.

<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
    <symbol id="arrow-down-small" viewBox="0 0 10 6">
        <path fill="currentColor" d="M5 6c-.256 0-.512-.098-.707-.293l-4-4c-.39-.39-.39-1.023 0-1.414.391-.39 1.023-.39 1.414 0l3.305 3.305L8.305.418c.4-.383 1.03-.372 1.414.025.384.397.373 1.031-.024 1.414l-4 3.862C5.5 5.907 5.25 6 5 6"></path>
    </symbol>
</svg>

```html_example
<div class="b-select">
	<select data-ref="field" class="b-select-input m-valid" id="" name="" required="" value="" maxlength="2147483647" aria-required="true" data-event-change="onChange" data-tau="" aria-describedby="" data-event-blur="validate">
		<option value="US" data-id="US" selected="">United States</option>
		<option value="CA" data-id="CA">Canada</option>
	</select>
	<svg aria-hidden="true" class="b-select-icon" width="10" height="6" focusable="false">
		<use href="#arrow-down-small"></use>
	</svg>
</div>
```

## Error State

```html_example
<div class="b-select">
	<select data-ref="field" class="b-select-input m-invalid" id="" name="" required="" value="" maxlength="2147483647" aria-required="true" data-event-change="onChange" data-tau="" aria-describedby="" data-event-blur="validate">
		<option value="" data-id="0">Please select</option>
		<option value="US" data-id="1">United States</option>
		<option value="CA" data-id="2">Canada</option>
	</select>
	<svg aria-hidden="true" class="b-select-icon" width="10" height="6" focusable="false">
		<use href="#arrow-down-small"></use>
	</svg>
</div>
```

*/

.b-select {
	position: relative;
	width: 100%;

	&-input {
		appearance: none;
		background: $color-white;
		border: 1px solid $color-black;
		color: inherit;
		cursor: pointer;
		font-weight: normal;
		height: $size-input-height;
		line-height: 18px;
		outline: none;
		padding: 0 40px 0 14px;
		padding-inline-end: 40px;
		padding-inline-start: 14px;
		position: relative;
		user-select: none;
		width: 100%;

		&:focus::-ms-value {
			background: transparent;
			color: $color-text;
		}

		&::-ms-expand {
			display: none;
		}

		&:-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 $color-text;
		}

		&.m-invalid {
			background: $color-error-obj;
			border: 2px solid $color-error;
		}

		&.m-disabled,
		&[disabled] {
			border-color: $color-action-disabled;
			color: $color-action-disabled;
			cursor: default;
			pointer-events: none;

			+ svg {
				color: $color-action-disabled;
			}
		}

		.b-country_selector & {
			padding-inline-start: 20px;
		}
	}

	&-input_as_text {
		appearance: none;
		background: none;
		border: none;
		max-width: 100%;
		pointer-events: none;
		white-space: normal;
	}

	&-icon {
		bottom: 0;
		pointer-events: none;
		position: absolute;
		right: 14px;
		top: 50%;
		transform: translateY(-50%);

		@include rtl {
			left: 14px;
			right: initial;
		}
	}
}

option {
	font-family: inherit;
	font-size: inherit;
}
