@use 'sass:color';

.b-product_tile {
	position: relative;

	&-top {
		margin-bottom: 10px;
		overflow: hidden;
		position: relative;
	}

	&-image_link {
		display: block;

		&.m-not_available {
			opacity: 0.5;
		}
	}

	&-image {
		@include g-image_container(_container, aspect-ratio(3, 4));

		img,
		video {
			@include g-image_container(_img);
		}
	}


	&-quick_view {
		background-color: rgba($color-bg, 0.8);
		border: none;
		bottom: 0;
		color: color.adjust($color-action, $lightness: -17%);
		font-weight: 500;
		left: 0;
		opacity: 0;
		position: absolute;
		text-transform: initial;
		transition-duration: $motion-fast;
		transition-property: color, opacity;
		width: 100%;

		@include media(md-down) {
			display: none;
		}

		&:hover {
			background-color: rgba($color-bg, 0.8);
			color: color.adjust($color-action, $lightness: -17%);
		}

		&.m-product_set {
			pointer-events: none;
		}

		&:focus,
		.b-product_tile-top:hover & {
			opacity: 1;
		}
	}

	&-no_available {
		background-color: $color-white;
		border-radius: 4px;
		bottom: 10px;
		color: $color-bright-red;
		font-size: 12px;
		font-weight: 400;
		left: 10px;
		letter-spacing: 1px;
		line-height: 1.83;
		padding: 2px 6px;
		pointer-events: none;
		position: absolute;
		text-transform: uppercase;

		@include media(sm) {
			font-size: 10px;
		}
	}

	&-title {
		font-weight: normal;
		letter-spacing: 1px;
		line-height: 1.6;
		margin-bottom: 5px;
		padding-right: 4px;
		text-transform: uppercase;
		font-family: $font-serif;
	}

	&-link {
		@include g-link;

		color: $color-black;
		display: -webkit-box; // stylelint-disable-line
		-webkit-box-orient: vertical; // stylelint-disable-line
		-webkit-line-clamp: 1; // stylelint-disable-line
		overflow: hidden;
		text-transform: uppercase;
		transition: color $motion-fast;
		word-break: break-word;
	}

	&-price {
		margin-bottom: 10px;
	}

	&-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
