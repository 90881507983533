@use 'sass:color';

.b-newsletters {
	$submit-width: 135px;

	display: flex;

	@include media(sm) {
		display: block;
	}

	&-caption {
		margin-right: 62px;

		@include media(sm) {
			margin-right: 0;
		}
	}

	&-group_wrapper {
		width: 515px;

		@include media(sm) {
			width: 100%;
		}
	}

	&-group {
		display: flex;
		margin-bottom: 15px;
		position: relative;

		&[hidden] {
			display: none;
		}
	}

	&-input_wrapper {
		flex-grow: 1;
		margin-right: 15px;

		@include media(sm) {
			margin-right: 10px;
		}
	}

	&-input {
		appearance: none;
		background: transparent;
		border: none;
		border-bottom: 1px solid $color-grey-15;
		border-radius: 0;
		box-shadow: none;
		box-sizing: border-box;
		font-family: inherit;
		height: 44px;
		line-height: 44px;
		padding: 10px 0;
		width: 100%;

		@include media(md-down) {
			font-size: 16px; // prevent iOS from zoom in page when user interact with inputs
		}

		&::-ms-clear {
			display: none;
		}

		&::placeholder {
			color: $color-grey-50;
			opacity: 1;
		}

		&.m-invalid {
			border: none;
			border-bottom: 1px solid #d54a4a;
		}
	}

	&-message_validation {
		color: $color-error;
		line-height: 1.2;
		margin-top: rh(2);
	}

	&-message_success {
		color: $color-black;
		font-size: 14px;

		@include media(sm) {
			text-align: center;
		}
	}

	&-message_success_announcement {
		@include hide(visually);
	}

	&-privacy {
		.b-checkbox {
			align-items: flex-start;
		}

		&[data-ref="agreeToMarketing"],
		&[data-ref="agreeToPrivacy"] {
			.b-form_section-required {
				display: none;
			}
		}

		a {
			@include g-link;

			display: inline-block;
			position: relative;
			text-decoration: underline;
			white-space: nowrap;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&-submit {
		font-size: 14px;
		height: 44px;
		width: $submit-width;
	}
}
