.b-stepper {
	$button-size: 47px;

	display: flex;
	user-select: none;
	width: $button-size * 3;

	&-button {
		border: 1px solid $color-grey73;
		cursor: pointer;
		display: block;
		line-height: $button-size;
		margin: -1px;
		min-height: $button-size;
		min-width: $button-size;
		text-align: center;
		touch-action: manipulation; // Prevent iOS from double tab to zoom

		&:active {
			background-color: $color-action;
			color: $color-white;
			fill: currentColor;
		}

		&[disabled] {
			background-color: $color-grey95;
			border-color: $color-grey73;
			color: $color-grey73;
			pointer-events: none;
		}
	}

	&-input {
		appearance: none;
		border: 1px solid;
		border-color: $color-grey73 $color-grey88;
		border-radius: 0;
		box-shadow: none;
		font-size: 16px; // This is needed to prevent iOS from zoom the page
		margin: -1px;
		min-height: $button-size;
		min-width: $button-size;
		padding: 0;
		position: relative;
		text-align: center;
		width: 100%;
		z-index: 1;

		&:focus {
			border-color: $color-action;
		}

		&[disabled] {
			background-color: $color-grey95;
			color: $color-grey46;
			cursor: default;
			opacity: 1;
			-webkit-text-fill-color: $color-grey46;
		}
	}
}
