.b-footer_top {
	display: flex;
	font-weight: 300;
	justify-content: space-between;
	line-height: 22px;
	padding: rh(12 0 9);

	@include media(sm) {
		flex-direction: column;
		padding: rh(9 0 0);
	}

	&-block {
		flex-basis: grid-span(3, 'lg');
		padding-inline-end: grid-gutter('lg');

		@include media(md) {
			flex-basis: grid-span(4, 'lg');
		}

		@include media(sm) {
			margin: rh(0 0 7);
			padding: 0;
		}

		&:last-child {
			padding: 0;
		}
	}

	&-title {
		color: adjust-color-to-bg($color-bg-footer-region-2);
		display: block;
		font-size: 16px;
		font-weight: 600;
		margin: rh(0 0 4);
	}
}
