.b-minicart_product {
	$root: &;

	border-top: 1px solid $color-divider-light;
	padding-bottom: 20px;
	padding-top: 20px;
	position: relative;

	&.m-order_history {
		.b-user_content & {
			margin-bottom: 0;
		}
	}

	&:first-child {
		border-top: none;
	}

	&.m-bonus {
		border-top: none;

		&::before {
			border-top: 1px dashed $color-divider-light;
			content: '';
			left: 0;
			position: absolute;
			top: 0;
			width: 75%;
		}
	}

	&.m-cancel {
		display: flex;
	}

	&-inner {
		display: grid;
		grid-template-areas:
			'msg . . .'
			'image details details remove'
			'image qty total total';
		grid-template-columns: 143px auto auto 48px;
		grid-template-rows: auto 1fr auto; // stylelint-disable-line
		position: relative;
		width: 100%;

		#{$root}.m-checkout & {
			grid-template-areas:
				'msg .'
				'image details'
				'image qty'
				'image total';
			grid-template-columns: 87px auto;
		}

		.b-order_details.m-guest & {
			grid-template-columns: 87px auto;
		}

		#{$root}.m-order_history & {
			grid-template-areas:
				'msg .'
				'image details'
				'image qty'
				'image total'
				'image stepper';
			grid-template-columns: 112px auto;
			grid-template-rows: 1fr; // stylelint-disable-line
		}
	}

	&-msg {
		background-color: $color-error-obj;
		border-color: $color-error;
		color: $color-info;
		grid-column: span 4;
		margin-bottom: 20px;
		padding: 4px 8px;
		text-align: center;

		&.m-gift {
			background-color: $color-warning-obj;
			border-color: $color-black;
			margin-bottom: 8px;
			padding: 0;
			text-align: start;

			#{$root}.m-checkout & {
				grid-column: span 2;
			}
		}
	}

	&-link {
		display: block;
	}

	&-image {
		grid-area: image;
	}

	&-details {
		grid-area: details;
		padding-inline-start: 20px;
	}

	&-picture {
		@include g-image_container(_container, aspect-ratio(3, 4));

		img {
			@include g-image_container(_img);
		}
	}

	&-title {
		font-weight: 400;
		letter-spacing: 1px;
		margin-bottom: 5px;
		text-transform: uppercase;

		a {
			@include g-link;

			text-decoration: none;
		}
	}

	&-availability {
		white-space: nowrap;

		.b-availability { // this removes extra spacing if no b-availability
			padding-top: 20px;
		}
	}

	&-attributes {}

	&-attribute_value {}

	&-bundle_title {
		margin-bottom: 8px;
	}

	&-bundle_item {
		margin-bottom: 4px;

		&:not(:last-child)::after {
			content: '\002C';
		}
	}

	&-attribute {
		margin-bottom: 4px;
		text-transform: capitalize;
		word-break: break-word;

		.b-user_content & {
			margin-bottom: 4px;
		}
	}

	&-price {
		margin-bottom: 4px;
	}

	&-price_each {
		#{$root}.m-checkout &,
		#{$root}.m-order_history & {
			display: inline-block;
			margin-inline-end: 4px;
		}
	}

	&-price_total {
		align-items: flex-end;
		display: flex;
		flex-direction: column;
		grid-area: total;
		justify-content: flex-end;

		#{$root}.m-summary & {
			font-weight: 400;
		}

		#{$root}.m-checkout &,
		#{$root}.m-order_history & {
			display: inline-block;
			font-weight: 500;
			margin-top: 10px;
			padding-inline-start: 20px;
		}

		#{$root}.m-order_history & {
			margin-top: 4px;
		}

		.b-price {
			display: inline-block;
			text-align: end;
		}

		.b-price-item {
			display: block;
			margin-inline-end: 0;
		}

		.b-price-item:not(:first-child) {
			margin-inline-start: 8px;
		}
	}

	&-promotions {
		margin: 8px 0;
	}

	&-info {
		grid-area: qty;
		margin-top: 12px;
		padding-inline-start: 20px;

		&.m-gift {
			margin-top: 32px;
		}

		.b-form_section-label {
			@include hide(visually);
		}

		#{$root}.m-checkout &,
		#{$root}.m-order_history & {
			margin-top: 0;
		}
	}

	&-qty {
		display: flex;
	}

	&-qty_inline {
		#{$root}.m-order_history & {
			margin-bottom: 0;
		}
	}

	&-stepper {
		grid-area: stepper;
		margin-top: 10px;
		padding-inline-start: 20px;
	}

	&-remove {
		grid-area: remove;
		margin-top: -8px;

		#{$root}.m-checkout & {
			display: none;
		}
	}

	&-remove_btn {
		@include g-button_iconed(40px, 40px);

		svg {
			height: 14px;
			width: 14px;
		}
	}

	&-checkbox {}
}
