.b-header_wishlist {
	@include media(lg-up) {
		@include g-button_iconed;
	}

	@include media(md-down) {
		@include g-link_hamburger;

		box-shadow: none;
		font-size: 14px;
		padding: 0 4px;
		text-transform: none;
	}

	@include hover-supported {
		svg {
			transform: translateY(0);
			transition: transform $motion-ease;
		}

		&:hover,
		&.m-hover {
			svg {
				transform: translateY(-4px);
			}
		}
	}

	&-icon {
		@include g-button_iconed(40px, 40px);

		stroke: black;


		.b-header_wishlist.m-animated & {
			animation: heart-bit ease-out 1s;
			animation-delay: 2s;
		}
	}

	&-copy {
		display: none;

		.b-menu_panel & {
			display: block;
		}
	}
}
