.b-icon_plus {
	display: block;
	flex-shrink: 0;
	height: 20px;
	margin-inline-start: auto;
	position: relative;
	width: 20px;

	&::after,
	&::before {
		background-color: $color-black;
		content: '';
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&::before {
		height: 1px;
		width: 20px;
	}

	&::after {
		display: block;
		height: 20px;
		width: 1px;

		[aria-expanded='true'] ~ &,
		[aria-expanded='true'] & {
			display: none;
		}
	}
}
