/*md

# b-link_email

Email link with **mailto:[email]** scheme

```html_example
<a href="mailto:customerservice@boilerplate.com" class="b-link_email">customerservice@boilerplate.com</a>
```
*/

.b-link_email {
	@include g-link(underlined);

	white-space: nowrap;
}
