.b-suggestions_guess {
	color: $color-text;
	line-height: 1.14;
	margin-bottom: 0;
	text-decoration: none;

	&.m-focused,
	&:hover {
		text-decoration: none;
	}

	&-correction_wrap {
		font-weight: 700;
	}

	&-correction {
		transition: color $motion-fast;
		unicode-bidi: isolate;

		.b-suggestions_guess.m-focused &,
		.b-suggestions_guess:hover & {
			color: $color-action;
		}
	}
}
