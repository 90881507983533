/*md

# b-price

Designed to provide same styles of pricing across different components without explicit CSS class.
`.b-price` inherit font-size from parent wrapper or general font-size

## Regular price

```html_example
<div class="b-price" data-tau-price="product_price">
	<span class="b-price-item" content="9.99" data-tau-price="new">
		$9.99
	</span>
</div>
```

## Sale price

```html_example
<div class="b-price" data-tau-price="product_price">
	<span class="b-price-to">Was</span>
	<span class="b-price-item m-old" content="11.99" data-tau-price="old">
		$11.99
	</span>
	<span class="b-price-to">, is</span>
	<span class="b-price-item" content="9.99" data-tau-price="new">
		$9.99
	</span>
</div>
```

## Price range

```html_example
<div class="b-price" data-tau-price="product_price">
	<span class="b-price-to">From</span>
	<span class="b-price-item" content="9.99" data-tau-price="new">
		$9.99
	</span>
	<span class="b-price-to">to</span>
	<span class="b-price-divider">-</span>
	<span class="b-price-item" content="11.99" data-tau-price="old">
		$11.99
	</span>
</div>
```

## Free price

```html_example
<div class="b-price" data-tau-price="product_price">
	<span class="b-price-item m-free" content="Free" data-tau-price="new">
		FREE
	</span>
</div>
```

*/

.b-price {
	display: flex;
	flex-wrap: wrap;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 1px;
	position: relative;

	&-item {
		display: inline-block;
		margin-inline-end: rh(2);
		white-space: nowrap;

		&:last-child {
			margin: 0;
		}

		&.m-old {
			color: $color-text-dimmed;
			text-decoration: line-through;
		}

		&.m-free {
			text-transform: uppercase;
		}
	}

	&-divider {
		margin-inline-end: rh(2);
	}

	.b-carousel-track & {
		font-size: 12px;
		letter-spacing: 0.5px;
	}
}
