.b-header_track_order {
	@include g-link_hamburger;

	padding: 0 4px;
	text-transform: none;

	&-icon {
		@include g-button_iconed(40px, 40px);
	}
}
