/*md

# Input

Default input element

## Default

```html_example
	<input
		class="b-input"
		type="text"
		placeholder="Promo Code"
		name="couponCode"
		required=""
		value=""
	>
```

## Invalid

```html_example
	<input
		class="b-input m-invalid"
		type="text"
		placeholder="Promo Code"
		name="couponCode"
		required=""
		value=""
	>
```

*/

.b-input {
	appearance: none;
	border: 1px solid $color-black;
	border-radius: 0;
	color: $color-black;
	cursor: text;
	height: $size-input-height;
	line-height: $size-input-height;
	padding: 0 20px;
	vertical-align: baseline;
	width: 100%;

	@include media(md-down) {
		font-size: 16px; // prevent iOS from zoom in page when user interact with inputs
	}

	@include placeholder(14px) {
		color: $color-grey-50;
	}

	&.m-invalid {
		border: 1px solid $color-error;
	}
}
