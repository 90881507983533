.b-footer_nav {
	color: $color-black;

	@include media(md-up) {
		border-top: 1px solid $color-divider-light;
		display: flex;
		padding: 48px 0 0;
	}

	@include media(sm) {
		margin: 0 -15px;
	}

	&-wrapper {
		padding-left: 15px;

		@include media(sm) {
			padding: 30px 0 0;
		}
	}

	&-title {
		@include media(sm) {
			@include g-accordion(_title);

			color: $color-black;
		}

		@include media(md-up) {
			color: $color-black;
			display: inline-block;
			font-size: 18px;
			font-weight: 400;
			letter-spacing: 1px;
			margin-bottom: 20px;
			position: relative;
			text-transform: uppercase;

			.b-icon_chevron {
				display: none;
			}
		}
	}

	&-button {
		@include media(sm) {
			@include g-accordion(_control);

			border-top: 1px solid $color-divider-light;
			font-weight: 400;
			letter-spacing: 0.5px;
			padding: 18px 25px 18px 16px;
		}

		@include media(md-up) {
			display: none;
		}
	}

	&-heading {
		@include media(sm) {
			display: none;
		}
	}

	&-content {
		@include media(sm) {
			@include g-accordion(_content);

			&[aria-hidden='false'] {
				@include g-accordion(_content, expanded);
			}
		}
	}

	&-list {
		@include media(sm) {
			@include g-accordion(_content_inner);

			padding: 4px 16px 23px;
		}
	}

	&-item {
		display: block;
		margin-bottom: 11px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&-link {
		@include g-link;

		padding: 4px 0;
	}

	&-column {
		@include media(md-up) {
			flex-basis: grid-span(3, 'lg');
			padding-inline-end: grid-gutter('lg');
		}

		@include media(sm) {
			@include g-accordion(_item);
		}
	}

	&-find_store {
		@include media(md-up) {
			flex-basis: grid-span(3, 'lg');
			margin-bottom: 30px;
			padding-top: 7px;
		}

		@include media(sm) {
			margin-bottom: 30px;
			padding: 0 15px;

			&:last-child {
				border-top: 1px solid $color-divider;
			}
		}

		.b-find_store {
			@include g-link;

			font-weight: 400;
			height: auto;
			width: auto;

			@include media(sm) {
				border: 1px solid $color-black;
				display: block;
				height: auto;
				letter-spacing: 0.5px;
				padding: 13px 0 12px;
				text-align: center;
				text-transform: uppercase;
				width: 100%;
			}
		}

		svg {
			height: 18px;
			margin-right: 5px;
			width: 18px;

			@include media(sm) {
				margin-right: 3px;
			}
		}
	}
}
