/*md

# b-message

TBD

*/

.b-message {
	background-color: $color-info-obj;
	border: 1px solid $color-grey-25;
	border-radius: 2px;
	color: $color-info;
	font-size: 12px;
	margin-bottom: 8px;
	padding: 6px 10px;
	text-align: center;

	&:last-child {
		margin: 0;
	}

	&.m-success {
		background-color: $color-success-obj;
		color: $color-black;
	}

	&.m-error {
		background-color: $color-error-obj;
		border-color: $color-error;
		color: $color-info;
	}

	&.m-warning {
		background-color: $color-warning-obj;
		border-color: $color-black;
	}

	// used as a promo banner
	&.m-order_confirmation {
		font-size: 16px;
		margin-top: 28px;
	}

	&.m-info {
		display: flex;
		font-size: 14px;
		padding: 10px 20px;
		position: relative;
		text-align: left;
	}

	&.m-disclaimer {
		margin-bottom: 20px;
	}

	&-icon {
		border: 1px solid;
		border-radius: 50%;
		flex: 0 0 18px;
		height: 18px;
		line-height: 1;
		text-align: center;
	}

	&-text {
		padding-left: 10px;
	}

	&-link {
		@include g-link;

		&.m-highlighted {
			font-weight: 500;
		}
	}
}
