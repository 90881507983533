.b-continue_shopping {
	align-items: center;
	display: inline-flex;
	text-decoration: none;

	&-icon {
		color: $color-action;
		margin-inline-end: rh(2);
		transform: rotate(90deg);
	}

	&-text {
		color: adjust-color-to-bg($color-bg);
		font-size: 12px;
		font-weight: 600;
		text-decoration: underline;
	}

	&:hover {
		text-decoration: none;
	}

	&:hover &-text {
		color: $color-action;
		text-decoration: none;
	}
}
