.b-search_input {
	$input-height: 48px;

	align-items: center;
	border-bottom: $global-line solid $color-divider;
	display: flex;
	position: relative;
	width: 100%;

	&-submit {
		@include g-button_iconed;

		color: $color-grey-50;
		width: auto;

		&:hover,
		&:focus {
			@include media(md-down) {
				background: none;
				color: $color-action;
			}
		}
	}

	&-input {
		appearance: none;
		background: transparent;
		border: none;
		border-radius: 0;
		box-sizing: border-box;
		color: adjust-color-to-bg($color-bg-header-line-1);
		height: $input-height;
		line-height: $input-height;
		padding: 0;
		padding-inline-start: 10px;
		width: 100%;

		@include media(md-down) {
			font-size: 16px; // prevent iOS from zoom in page when user interact with inputs
		}

		&::-webkit-search-cancel-button,
		&::-webkit-search-decoration,
		&::-webkit-inner-spin-button {
			appearance: none;
		}

		&::-ms-clear {
			display: none;
		}

		&::placeholder {
			color: $color-text-dimmed;
		}
	}

	&-clear {
		@include g-button_iconed;

		opacity: 0;
		pointer-events: none;
		transition: opacity $motion-ease;

		&.m-visible {
			opacity: 1;
			pointer-events: all;
		}
	}
}
