/*md

# b-availability

Designed to provide same styles of availability across different components.

```html_example
<div data-ref="container" class="b-availability m-instock" aria-label="Availability">
	<span class="b-availability-instock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd" stroke-width="2">
				<circle cx="10" cy="10" r="9" fill="currentColor" stroke="currentColor"></circle>
				<path stroke="white" d="M6 9.914L10 13 18 2"></path>
			</g>
		</svg>
	</span>

	<span class="b-availability-outofstock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd">
				<circle cx="10" cy="10" r="10" fill="currentColor"></circle>
				<path stroke="white" stroke-linejoin="round" stroke-width="2" d="M0 0L8 8M0 8L8 0" transform="translate(6 6)"></path>
			</g>
		</svg>
	</span>

	<div class="b-availability-status">
		In Stock
	</div>
</div>
```

```html_example
<div data-ref="container" class="b-availability m-outofstock" aria-label="Availability">
	<span class="b-availability-instock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd" stroke-width="2">
				<circle cx="10" cy="10" r="9" fill="currentColor" stroke="currentColor"></circle>
				<path stroke="white" d="M6 9.914L10 13 18 2"></path>
			</g>
		</svg>
	</span>

	<span class="b-availability-outofstock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd">
				<circle cx="10" cy="10" r="10" fill="currentColor"></circle>
				<path stroke="white" stroke-linejoin="round" stroke-width="2" d="M0 0L8 8M0 8L8 0" transform="translate(6 6)"></path>
			</g>
		</svg>
	</span>

	<div class="b-availability-status">
		Out Of Stock
	</div>
</div>
```

```html_example
<div data-ref="container" class="b-availability m-lowinstock" aria-label="Availability">
	<span class="b-availability-instock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd" stroke-width="2">
				<circle cx="10" cy="10" r="9" fill="currentColor" stroke="currentColor"></circle>
				<path stroke="white" d="M6 9.914L10 13 18 2"></path>
			</g>
		</svg>
	</span>

	<span class="b-availability-outofstock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd">
				<circle cx="10" cy="10" r="10" fill="currentColor"></circle>
				<path stroke="white" stroke-linejoin="round" stroke-width="2" d="M0 0L8 8M0 8L8 0" transform="translate(6 6)"></path>
			</g>
		</svg>
	</span>

	<div class="b-availability-status">
		5 Items In Stock
	</div>
</div>
```

*/

.b-availability {
	$root: &;

	align-items: center;
	color: $color-grey-50;
	display: flex;
	font-size: 14px;

	&.m-instock {
		display: none;
	}

	&-status {
		&::before {
			background-color: $color-success;
			border-radius: 50%;
			content: '';
			display: inline-block;
			height: 10px;
			margin-inline-end: 8px;
			width: 10px;
		}

		#{$root}.m-outofstock &::before {
			background-color: $color-accent;
		}

		#{$root}.m-lowinstock &::before {
			background-color: $color-warning;
		}

		#{$root}.m-instoreonly &::before {
			background-color: $color-error;
		}

		#{$root}.m-backorder &::before {
			background-color: $color-warning;
		}

		#{$root}.m-preorder &::before {
			background-color: $color-text;
		}
	}

	&-message {
		&.m-instock_date {
		}

		&.m-select_options_first {
			color: $color-error;
		}
	}

	.b-product_details-availability & {
		margin: 30px 0 20px;

		.l-pdp.m-quick_view & {
			margin: 0;
		}
	}

	.l-pdp-main.m-bundle & { // stylelint-disable-line no-descending-specificity
		font-size: 14px;
	}
}
