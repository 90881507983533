.b-suggestions_category {
	color: $color-text;
	text-decoration: none;
	transition: color $motion-fast;

	&-title {
		text-decoration: underline;
	}

	&:hover,
	&.m-focused {
		color: $color-action;
		text-decoration: underline;
	}

	&-image {
		$image-size: 30px;

		@include g-image_container(_container);

		border-radius: $global-radius;
		height: $image-size;
		margin-inline-end: rh(3);
		max-height: $image-size;
		max-width: $image-size;
		min-width: $image-size;
		padding: 0;
		width: $image-size;

		img {
			@include g-image_container(_img);
		}
	}

	&-parent_title {
		font-weight: normal;
	}
}
