/*md

# b-link_phone

Phone link with **tel:[number]** scheme

```html_example
<a href="tel:1-888-222-3380" class="b-link_phone">1-888-222-3380</a>
```
*/

.b-link_phone {
	@include g-link(underlined);

	unicode-bidi: isolate;
	white-space: nowrap;
}
