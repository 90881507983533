.b-wishlist_button {
	align-items: center;
	cursor: pointer;
	display: flex;
	letter-spacing: 1px;
	margin: 0;
	text-transform: uppercase;
	transition: $motion-fast;
	transition-property: color, fill;

	&:hover {
		color: $color-action;
	}

	svg {
		height: 18px;
		width: 24.6px;
		stroke:$color-grey-50;
	}

	&[aria-busy='true'] {
		cursor: wait;
		pointer-events: none;
	}

	&[aria-pressed='true'] {
		color: $color-text;

		svg {
			stroke:$color-black;
		}
	}

	&-icon {
		@include g-button_iconed(40px, 40px);

		&:hover {
			color: inherit;
		}
	}

	use {
		color: inherit;
		fill: none;
	}

	&[aria-pressed='true'] use {
		color:$color-black;
		fill:$color-black;
		stroke:$color-black;
	}

	&[aria-pressed='true'].m-added {
		svg {
			color:$color-black;
			fill:$color-black;
			stroke:$color-black;
		}
	}
}

.b-wishlist_button.m-tile {
	stroke: #8F8F8F;
	z-index: 2;
}
