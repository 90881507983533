/*md
@no-stat

# global-animations

This component is designed to store all global animation and include it only once in single place
so all other components could reuse this animations.
*/

@at-root {
	@keyframes fade-in {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes animation-chevron-up {
		0% {
			transform: translateY(-50%) rotate(46deg);
			width: 10px;
		}

		50% {
			transform: translate(-20%, -50%) rotate(0deg);
			width: 8px;
		}

		100% {
			transform: translateY(-50%) rotate(-46deg);
			width: 10px;
		}
	}

	@keyframes animation-chevron-down {
		0% {
			transform: translateY(-50%) rotate(-46deg);
			width: 10px;
		}

		50% {
			transform: translate(-20%, -50%) rotate(0deg);
			width: 8px;
		}

		100% {
			transform: translateY(-50%) rotate(46deg);
			width: 10px;
		}
	}

	@keyframes thumbs-zoom {
		0% {
			transform: translateY(35px);
		}

		100% {
			transform: translateY(0);
		}
	}

	@keyframes slide-from-bottom {
		0% {
			transform: translateY(100%);
		}

		100% {
			transform: translateY(0);
		}
	}

	@keyframes dialog-opening {
		0% {
			transform: scale(0.8);
		}

		100% {
			transform: scale(1);
		}
	}

	@keyframes hero-carousel-progress {
		from {
			stroke-dashoffset: 104;
		}

		to {
			stroke-dashoffset: 1;
		}
	}

	@keyframes heart-bit {
		0% {
			transform: scale(1);
		}

		25% {
			transform: scale(1.2);
		}

		50% {
			transform: scale(1);
		}

		75% {
			transform: scale(1.2);
		}
	}

	@media (prefers-reduced-motion) {
		// stylelint-disable
		* {
			animation: none !important;
			transition: none !important;
		}
		// stylelint-enable
	}
}
