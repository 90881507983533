.b-payment_total {
	width: 100%;

	@include media(lg-up) {
		display: none;
	}

	.b-minicart & {
		display: table;
	}

	&-name,
	&-value {
		font-size: 16px;
		font-weight: 700;
		padding: rh(5 0);
		text-align: start;
	}

	&-value {
		text-align: end;
		vertical-align: top;
	}

	&-tax {
		color: $color-grey-50;
		display: block;
		font-size: 12px;
		font-weight: 500;
	}
}
