// stylelint-disable

.onetrust-pc-dark-filter,
#onetrust-pc-sdk.otPcCenter {
	z-index: z(notifier) !important;
}

#onetrust-banner-sdk.otFlat {
	background-color: rgba(white, 0.8) !important;
	z-index: z(notification-panel) !important;
}

// stylelint-enable
