.b-suggestions_product {
	$root: &;

	color: $color-text;
	display: flex;
	margin-bottom: 15px;
	text-decoration: none;

	&:last-child {
		margin-bottom: 0;
	}

	&-picture_wrap {
		margin-inline-end: 10px;
	}

	&-picture {
		@include g-image_container(_container, aspect-ratio(3, 4));

		border-radius: $global-radius;
		max-width: 100%;
		width: 80px;

		img {
			@include g-image_container(_img);
		}
	}

	&-title {
		font-weight: 400;
		letter-spacing: 1px;
		margin-bottom: 4px;
		max-width: 300px;
		text-transform: uppercase;
		transition: color $motion-fast;

		@include hover-supported {
			#{$root}:hover & {
				text-decoration: underline;
			}
		}

		#{$root}:hover &,
		#{$root}.m-focused & {
			color: $color-action;
		}
	}

	&-price_info {
		margin-inline-end: rh(2);
	}

	&-info {
		color: $color-almost-black;

		.b-price {
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.5px;
			line-height: 1.83;
		}
	}
}
