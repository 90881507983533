/*md

# Apple pay button

Part of the styling of Apple pay button is implemented by Demandware. Those styles inserted throgh
isactivedatahead tag. [Please see](https://confluence.ontrq.com/display/ACDC/ApplePay+button+styling)

We overwrite Demandware styles and provide modern approach for button styling and localization
possibilities.

 */

.b-apple_pay {
	margin: rh(3.5 0);

	&.m-disabled {
		opacity: 0.4;
		pointer-events: none;
	}

	&-button {
		&#{&} {
			&,
			&:hover,
			&:active {
				// This is modification of default Demandware styles that inserted in header
				// throgh isactivedatahead. As approach used background-image:-webkit-named-image(apple-pay-logo-white);

				background-size: 75% 52%;
				border-radius: 0;
				cursor: pointer;
				margin: 0;

				// Reset Demandware styles if iOS 10+ and use -apple-pay-button
				@supports (-webkit-appearance: -apple-pay-button) {
					-webkit-appearance: -apple-pay-button; // stylelint-disable-line
					background: none;
					border: none;
					-apple-pay-button-style: white-outline;
				}
			}

			@supports (-webkit-appearance: -apple-pay-button) {
				&::after {
					content: '';
				}
			}
		}

		// Cover different button types options of Demandware apple pay
		&.dw-apple-pay-logo-white { // stylelint-disable-line selector-class-pattern
			-apple-pay-button-style: unquote('white'); // stylelint-disable-line color-named
		}

		&.dw-apple-pay-logo-white.dw-apple-pay-border { // stylelint-disable-line selector-class-pattern
			-apple-pay-button-style: white-outline;
		}

		// Modify button messaging on checkout ("Order with 🍏Pay")
		&.m-checkout {
			margin-bottom: rh(5.5);

			@supports (-webkit-appearance: -apple-pay-button) {
				&,
				&:hover,
				&:active {
					-apple-pay-button-type: order;
				}
			}
		}

		// Modify button messaging on PDP ("Buy with 🍏Pay")
		&.m-pdp {
			@supports (-webkit-appearance: -apple-pay-button) {
				&,
				&:hover,
				&:active {
					-apple-pay-button-style: unquote('white'); // stylelint-disable-line color-named
					-apple-pay-button-type: buy;
				}
			}
		}

		.b-apple_pay.m-disabled & {
			@include g-button(disabled);
		}
	}

	&-description {
		margin: rh(6 0 5);
	}

	.b-proceed_checkout & {
		@include media(md) {
			flex: 1;
			margin: 0;
		}
	}
}
