/*md

# Product tile swatches

Swatch that used on product tile.

This is simplified version of swatch that use links to appropriate product variation.

```html_example
<div class="b-product_tile_swatches">
	<a class="b-product_tile_swatches-swatch m-selected" href="" title="Green color" data-attr-value="Green" data-tau-color-id="Green" data-tau="color_swatch_selected" style="background-color: #2e7d32"></a>
	<a class="b-product_tile_swatches-swatch " href="#" title="Black color" data-attr-value="Black" data-tau-color-id="Black" style="background-color: #000000"></a>
	<a class="b-product_tile_swatches-swatch " href="#" title="White color" data-attr-value="White" data-tau-color-id="White" style="background-color: #FFFFFF"></a>
	<a class="b-product_tile_swatches-leftover" href="#" title="Show all" aria-label="Show all" data-tau="product_color_showAll">+1</a>
</div>
```
*/

.b-product_tile_swatches {
	align-items: center;
	display: flex;
	margin: 10px 0;

	&-swatch {
		background-size: cover;
		border: 1px solid transparent;
		border-radius: 50%;
		height: 24px;
		margin-right: 15px;
		transition: $motion-ease;
		transition-property: box-shadow, border-color;
		width: 24px;

		&:last-child {
			margin-right: 0;
		}

		&:hover,
		&.m-selected {
			border-color: $color-black;
			box-shadow: inset 0 0 0 1px $color-bg;
		}

		&.m-outline {
			border-color: $color-grey-50;

			&:hover,
			&.m-selected {
				box-shadow: inset 0 0 0 1px $color-grey-25;
			}
		}
	}

	&-leftover {
		@include g-link;

		text-decoration: none;
		transition: color $motion-fast;
	}
}
