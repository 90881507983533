.b-footer {
	&-region {
		&.m-1 {
			background-color: $color-bg-footer-region-1;
			color: adjust-color-to-bg($color-bg-footer-region-1);
			padding: 50px 0;

			@include media(sm) {
				padding: 30px 0 20px;
			}
		}

		&.m-2 {
			background-color: $color-bg-footer-region-2;
			color: adjust-color-to-bg($color-bg-footer-region-2, rgba($color-white, 0.8), rgba($color-black, 0.8));
		}
	}

	&-inner {
		@include g-section_holder;
	}

	&-content {
		display: flex;
		justify-content: center;

		@include media(sm) {
			flex-direction: column;
		}
	}

	&-security_shopping {
		color: adjust-color-to-bg($color-bg-footer-region-2, $color-white, $color-text-grey);
		font-size: 12px;
		font-weight: 500;
		margin: rh(0 0 7);
	}

	&-payment_methods {
		margin: rh(0 0 7);
	}

	a, p, span,
	h1, h2, h3, h4, h5, h6 {
		font-family: $font-serif;
	}
}
