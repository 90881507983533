// Cookies acceptance / Consent tracking modeless dialog
.b-notification_dialog {
	background: rgba($color-bg-notification-panel, 0.95);
	color: adjust-color-to-bg($color-bg-notification-panel);
	padding: rh(4 0);

	&-inner {
		@include g-section_holder;

		align-items: center;
		display: flex;

		@include media(sm) {
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}

	&-title {
		@include hide(visually);
	}

	&-content {
		font-weight: 300;
		margin: 0 auto;
		max-width: 1000px;

		@include media(sm) {
			margin-bottom: rh(6);
			width: 100%;
		}
	}

	a {
		color: inherit;
		display: inline-block;
		text-decoration: underline;
		white-space: nowrap;

		&:hover,
		&:focus {
			opacity: 0.8;
		}
	}

	&-button {
		margin: rh(0 6);

		@include media(md) {
			margin-inline-end: 0;
		}

		@include media(sm) {
			margin: 0;
			white-space: normal;
			width: 49%;
		}

		&.m-outline {
			border-color: adjust-color-to-bg($color-bg-notification-panel);
			color: adjust-color-to-bg($color-bg-notification-panel);

			&:hover {
				background: rgba($color-white, 0.1);
				border-color: adjust-color-to-bg($color-bg-notification-panel);
			}
		}

		& + & {
			border: 0;

			@include media(md-down) {
				padding: rh(0 2);
			}
		}
	}
}
