/*md

# b-button

Please see [g-button](./02-components-g-button.html) for details.

## Primary Default
```html_example
<button type="button" class="b-button">
	Default #1
</button>

<button type="button" class="b-button m-hover">
	On hover
</button>

<button type="button" class="b-button m-disabled">
	Disabled
</button>
```

```html_example
<button type="button" class="b-button m-outline">
	Default #1
</button>

<button type="button" class="b-button m-outline m-hover">
	On hover
</button>

<button type="button" class="b-button m-outline m-disabled">
	Disabled
</button>
```

### Recommended to use #3 and #4 when background darker than #808080

```html_example
<div style="background-color: #7b7b7b; padding: 15px 30px;">
	<button type="button" class="b-button m-dark_bg">
		Default #1
	</button>

	<button type="button" class="b-button m-hover m-dark_bg">
		On hover
	</button>

	<button type="button" class="b-button m-disabled m-dark_bg">
		Disabled
	</button>
</div>
```

```html_example
<div style="background-color: #7b7b7b; padding: 15px 30px;">
	<button type="button" class="b-button m-outline m-dark_bg">
		Default #1
	</button>

	<button type="button" class="b-button m-outline m-hover m-dark_bg">
		On hover
	</button>

	<button type="button" class="b-button m-outline m-disabled m-dark_bg">
		Disabled
	</button>
</div>
```

## Secondary

```html_example
<button type="button" class="b-button m-secondary">
	Default
</button>

<button type="button" class="b-button m-secondary m-hover">
	On hover
</button>

<button type="button" class="b-button m-secondary m-disabled">
	Disabled
</button>
```

## Links

```html_example
<button type="button" class="b-button m-link">
	<span class="b-button-text">Women</span>
</button>

<button type="button" class="b-button m-link m-disabled">
	<span class="b-button-text">Disabled link</span>
</button>
```

```html_example
<button type="button" class="b-button m-link m-type_2">
	<span class="b-button-text">Category</span>
</button>

<button type="button" class="b-button m-link m-type_2 m-disabled">
	<span class="b-button-text">Disabled link</span>
</button>
```

## Button iconed
```html_example
<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
    <symbol id="arrow-down" viewBox="0 0 10 6">
		<path fill="currentColor" d="M5 6c-.256 0-.512-.098-.707-.293l-4-4c-.39-.39-.39-1.023 0-1.414.391-.39 1.023-.39 1.414 0l3.305 3.305L8.305.418c.4-.383 1.03-.372 1.414.025.384.397.373 1.031-.024 1.414l-4 3.862C5.5 5.907 5.25 6 5 6"></path>
	</symbol>
</svg>

<button type="button" class="b-button m-icon m-round">
	<svg width="18" height="18" aria-hidden="true" focusable="false">
		<use href="#arrow-down"></use>
	</svg>
</button>
```

*/

@use 'sass:color';

.b-button {
	$_root: &;

	@include g-button;

	background: $color-button;
	border: none;
	border-radius: $global-radius;
	color: $color-button-text;
	font-size: 14px;
	letter-spacing: 1px;
	padding: 10px 40px;

	&.d-none {
		display: none;
	}

	&.visual-hidden {
		visibility: hidden;
	}

	@include hover-supported {
		&:hover,
		&.m-hover {
			background: $color-button-hover;
			text-decoration: none;
			text-shadow: 0.5px 0 0 $color-button, -0.5px 0 0 $color-button;
		}
	}

	&:disabled,
	&.m-disabled {
		background: $color-button-disabled;
		color: $color-button-disabled-text;
		pointer-events: none;
	}

	&.m-outline {
		background: transparent;
		border: 1px solid $color-button;
		color: $color-button;

		&:disabled,
		&.m-disabled {
			border-color: $color-button-outline-disabled;
			color: $color-button-outline-disabled;
		}
	}

	&.m-dark_bg {
		background: $color-button-dark-bg;
		color: $color-button-dark-bg-text;

		@include hover-supported {
			&:hover,
			&.m-hover {
				background: $color-button-dark-bg-hover;
			}
		}

		&:disabled,
		&.m-disabled {
			background: $color-button-dark-bg-disabled;
			color: $color-button-dark-bg-disabled-text;
		}

		&.m-outline {
			background: transparent;
			border-color: $color-button-dark-bg;
			color: $color-button-dark-bg;

			@include hover-supported {
				&:hover,
				&.m-hover {
					border-color: $color-button-dark-bg-hover;
				}
			}

			&:disabled,
			&.m-disabled {
				border-color: $color-button-dark-bg-disabled;
				color: $color-button-dark-bg-disabled;
			}
		}
	}

	&.m-secondary {
		background: $color-button-secondary;
		color: $color-button-secondary-text;

		@include hover-supported {
			&:hover,
			&.m-hover {
				background: $color-button-secondary-hover;
			}
		}

		&:disabled,
		&.m-disabled {
			background: $color-button-secondary-disabled;
			color: $color-button-disabled;
		}
	}

	&.m-link {
		@include g-link;

		background: transparent;
		border-color: transparent;
		color: inherit;
		font-size: 14px;
		padding: 0;
		text-transform: uppercase;

		&.m-type_2 {
			font-size: 16px;
			letter-spacing: normal;
			text-transform: none;

			#{$_root}-text {
				&::before {
					top: 100%;
				}
			}
		}

		&:disabled,
		&.m-disabled {
			color: $color-button-link-disabled;
		}
	}

	&.m-icon {
		@include g-button_iconed;

		@include hover-supported {
			svg {
				transform: translateY(0);
				transition: transform $motion-ease;
			}

			&:hover,
			&.m-hover {
				svg {
					transform: translateY(-4px);
				}
			}
		}
	}

	&.m-paypal {
		background: #ffc439; // Hardcoded color as PayPal recommended styling
		color: $color-text;

		&:hover {
			box-shadow: inset 0 0 100px 100px rgb(0 0 0 / 5%); // Hardcoded box-shadow as PayPal recommended styling
			text-shadow: none;
		}
	}

	&.m-round {
		border: 1px solid $color-button;
		border-radius: 50%;
	}

	&.m-small {
		height: 24px;
	}

	&.m-medium {
		height: 38px;
	}

	&.m-width_full {
		width: 100%;
	}

	&.m-long_text {
		height: auto;
		min-height: $size-input-height;
		padding-left: 15px;
		padding-right: 15px;
		text-align: center;
		white-space: normal;
		word-break: break-word;
	}
}
