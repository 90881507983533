.b-search_dialog {
	height: 100%;
	width: 100%;

	&-inner {
		background-color: $color-white;
		display: flex;
		flex-direction: column;
		max-height: 100%;
		position: relative;
		width: 100%;
		z-index: z(search);
	}

	&-inner_top_content {
		@include g-section_holder;

		display: flex;
		justify-content: center;

		@include media(sm) {
			padding-right: 0;
		}
	}

	&-form_wrap {
		align-items: center;
		display: flex;
		justify-content: center;
		margin: 10px 0 0;
		position: relative;
		width: 100%;

		@include media(lg-up) {
			margin: rh(10 0 0);
			padding: 0;
			width: grid-span($column: 6);
		}
	}

	&-cancel {
		cursor: pointer;
		height: $size-input-height;
		letter-spacing: 1px;
		padding: 0 rh(5);
		text-transform: uppercase;
		transition: color $motion-fast;

		@include media(sm) {
			padding-right: 15px;
		}

		@include media(lg-up) {
			left: 100%;
			position: absolute;
		}

		&:focus,
		&:hover {
			color: $color-action;
		}
	}
}
