/*md

# b-dialog

### We have two types of dialog: *Usual dialog* and *Search suggestions dialog*

The usual dialog have different modifiers **m-*** which depend on the width of the dialog

```html_example
<div>
	<button class="b-button m-outline"
			type="button"
			data-widget="emitBusEvent"
			data-bus-event-type="dialog.show"
			data-event-click.prevent="emitBusEvent"
			data-modal-config="{&quot;content&quot;: &quot; &quot;}"
	>
		Show usual dialog
	</button>
	<button class="b-button m-outline" data-widget="emitBusEvent" data-bus-event-type="page.show.searchbox" data-event-click.prevent="emitBusEvent">
		Show search suggestions dialog
	</button>
</div>
<div data-widget="globalModal" data-disable-rendering="false">
    <div class="b-dialog" data-ref="container" hidden></div>
    <script type="template/mustache" data-ref="template">
        <div data-ref="container" class="b-dialog m-reset_password">
            <div class="b-dialog-window m-top_dialog" role="dialog" data-ref="dialog" aria-modal="true">
                <div class="b-dialog-header">
                    <h2 class="b-dialog-title" id="forget-password-form-title">Forgot password?</h2>
                    <div data-tau="forgot_password_close">
                        <button class="b-dialog-close" type="button" data-dismiss="modal" data-event-click.prevent="cancel">
                            <svg aria-hidden="true" width="18" height="18" viewBox="0 0 18 18" focusable="false">
                                <use href="#close"></use>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="b-dialog-body">
                    <p class="b-form_section">Don't worry - it's easily done! Just enter your email address below and we'll send you a link to reset your password.</p>
                    <form class="reset-password-form" action="" method="POST">
                        <div data-widget="inputEmail" class="b-form_section m-required">
                            <label class="b-form_section-label">Email</label>
                            <input type="email" class="b-input">
                        </div>
                        <div class="b-dialog-footer m-actions">
                            <button type="submit" class="b-button m-width_full">Reset password</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </script>
</div>
<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
    <symbol id="close">
        <path fill="currentColor" d="m0.515,0.515c0.39,-0.39 1.023,-0.39 1.414,0l7.071,7.07l7.071,-7.07c0.39,-0.39 1.024,-0.39 1.414,0c0.39,0.39 0.39,1.023 0,1.414l-7.07,7.071l7.07,7.071c0.39,0.39 0.39,1.024 0,1.414c-0.39,0.39 -1.023,0.39 -1.414,0l-7.071,-7.07l-7.071,7.07c-0.39,0.39 -1.024,0.39 -1.414,0c-0.39,-0.39 -0.39,-1.023 0,-1.414l7.07,-7.071l-7.07,-7.071c-0.39,-0.39 -0.39,-1.024 0,-1.414z"></path>
    </symbol>
</svg>

<div data-widget="searchBox" data-disable-rendering="true">
    <div data-ref="container" class="b-dialog m-search_suggestions" data-event-click.self="closeModal">
        <div class="b-search_dialog" data-ref="dialog" role="dialog">
            <div class="b-search_dialog-inner">
                <div class="b-search_dialog-inner_top">
                    <div class="b-search_dialog-inner_top_content">
                        <div class="b-search_dialog-form_wrap">
                            <form role="search" method="get" name="simpleSearch" class="b-search_input">
                                <button class="b-search_input-submit" type="submit">
                                    <svg width="23" height="23" viewBox="0 0 27 28" focusable="false">
                                        <path fill="none" fill-rule="evenodd" stroke="currentColor" stroke-width="2" d="M19.484 19.984c4.1-4.1 4.066-10.784-.078-14.927C15.263.913 8.58.879 4.48 4.979c-4.1 4.1-4.066 10.784.078 14.927 4.143 4.144 10.826 4.178 14.927.078zm-.567-.355l7.239 7.494"></path>
                                    </svg>
                                </button>
                                <input role="combobox" id="header-search-input" class="b-search_input-input" type="search" name="q" value="" placeholder="Search">
                            </form>
                            <button type="button" class="b-search_dialog-cancel" data-event-click="closeSearch">Cancel</button>
                        </div>
                    </div>
                </div>
                <div role="listbox" id="search-suggestions-list" class="b-suggestions m-active">
                    <div role="none" class="b-suggestions-inner">
                        <div role="none" class="b-suggestions-section m-content">
                            <div role="none" class="b-suggestions-title">Quick Links</div>
                            <a role="option" id="result-item-0" class="b-suggestions-option b-suggestions-link" href="#">Privacy Policy</a>
                            <a role="option" id="result-item-1" class="b-suggestions-option b-suggestions-link" href="#">Men</a>
                            <a role="option" id="result-item-2" class="b-suggestions-option b-suggestions-link" href="#">Women</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
```
*/

.b-dialog {
	@include g-dialog_backdrop;

	justify-content: center;
	padding: rh(10 0);

	&-window {
		animation: dialog-opening $motion-ease-popups;
		background-color: $color-white;
		border-radius: $global-radius;
		box-shadow: $depth-3; // previous added dialog will have less depth that one on top
		color: $color-text;
		margin: auto;
		max-width: 960px;
		min-height: 90vh;
		padding: 0 48px 36px;
		position: relative;
		transform-origin: top center;
		width: auto;
		z-index: z(modal);

		@include media(lg-up) {
			margin: auto rh(8);
		}

		@include media(md-up) {
			min-width: 600px;
		}

		@include media(md) {
			max-width: calc(100% - #{grid-margin(md) * 2});
		}

		@include media(sm) {
			max-width: calc(100% - #{grid-margin(sm) * 2});
			min-width: 345px;
			padding: 0 16px 36px;
		}

		&.m-active {
			min-height: auto;
		}

		&.m-widget-loading::before {
			@include g-spinner(3em);

			left: 50%;
			margin: -1em 0 0 -1em;
			position: absolute;
			top: 50%;
		}
	}

	&-header {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
		min-height: 40px; // prevent overlapping close button
		padding-top: 40px;

		&.m-hide_lg {
			@include media(lg-up) {
				display: none;
			}
		}
	}

	&-title {
		@include g-heading_3;

		&.m-small {
			@include g-heading_5;

			display: block;
			text-align: center;
			width: 100%;
		}

		&.m-visually_hidden {
			@include hide(visually);

			text-transform: none;
		}
	}

	&-close {
		@include g-button_iconed;

		position: absolute;
		right: 4px;
		top: 40px;

		@include rtl {
			left: 0;
			right: initial;
		}

		svg {
			height: 16px;
			width: 16px;
		}
	}

	&-body {
		line-height: 1.71;
		margin-top: 8px;
	}

	&-footer {
		display: flex;
		justify-content: flex-end;
		margin-top: 30px;

		@include media(sm) {
			flex-direction: column;
		}

		button {
			font-size: 14px;
			width: 100%;
		}

		button + button {
			@include media(md-up) {
				margin-inline-start: rh(2);
			}

			@include media(sm) {
				margin-top: rh(2);
			}
		}
	}
}

.b-dialog.m-size_guide {
	.b-dialog-window {
		min-height: 30vh;
		min-width: auto;
		width: 900px;
	}
}

.b-dialog.m-quick_view {
	$quick-view-modal-indent-right: 22;

	.b-dialog-window {
		min-height: 80vh;

		@include media(md) {
			min-width: calc(100% - #{grid-margin(md) * 2});
		}

		@include media(lg-up) {
			max-width: 100%;
			padding: rh(0 $quick-view-modal-indent-right 12 7);
			width: 1264px;
		}
	}

	.b-dialog-body {
		display: none;
	}
}

.b-dialog.m-search_suggestions {
	align-items: flex-start;
	display: none;
	overflow-y: auto;
	padding: 0;

	&.m-opened { // iOS would not set focus to input if inner part will be with display:none
		display: block;
	}

	&::after {
		touch-action: none;
	}
}

.b-dialog.m-reset_password {
	.b-dialog-window {
		min-width: auto;
		width: 560px;
	}
}

.b-dialog.m-delete_address {
	.b-dialog-window {
		max-width: 100%;
		min-width: auto;
		width: 524px;
	}
}

.b-dialog.m-store_pickup {
	.b-dialog-window {
		min-height: 70vh;

		@include media(md) {
			min-width: calc(100% - #{grid-margin(md) * 2});
		}

		@include media(lg-up) {
			max-width: 100%;
			padding: 0 40px 28px;
			width: 1264px;
		}
	}
}

.b-dialog.m-countdown {
	.b-dialog-window {
		min-height: auto;
	}
}
