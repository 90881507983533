/*md
@no-stat

# Breakpoints

## Boilerplate breakpoints

Boilerplate has 4 main breakpoints that targeted to [supported devices](https://confluence.ontrq.com/display/RSB/SFRA+BP+-+Supported+Browsers+and+Devices)
 - iPhone X, iPad, MS Windows desktop / Macbook Pro retina

** Please not iPad landscape - is LG breakpoint **

[See more info](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

## Supported screen resolutions

Boilerplate is come "Retina ready". It supports MDPI and XHDPI pixel density across all site.

| Device             | Screen Resolution, CSS pixels | Pixel density |
|--------------------|-------------------------------|---------------|
| Desktop Windows PC | 1920x1080                     | MDPI          |
| Macbook pro 13     | 1280x800 / 1440x900           | XHDPI         |
| iPad Air 2         | 1024x768                      | XHDPI         |
| iPhone X           | 375x812                       | XHDPI         |
| Samsung Galaxy S9  | 360x740                       | XHDPI         |

*/
/*md
@no-stat

# Media queries (breakpoints)

We have a `media` mixin for make it easier to implement responsive styling via media queries.

You can nest them right within other blocks of CSS,which puts the properties and values you are changing right next
to each other.
That creates an obvious connection between them, which is a much nicer authoring experience than trying to maintain
those changes separated by tons of other code or in a different file.

## Configuration

**Site Layout Conception** details with examples you can find [here](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

`media` mixin works with `$media` map where `media-name: media query`

This is how `$media` map looks:

```scss
$media: (
	sm: 'screen and (max-width: 767px)',
	md: 'screen and (min-width: 768px) and (max-width: 1199px)',
	lg: 'screen and (min-width: 1200px)',
	xl: 'screen and (min-width: 1201px)',
	md-up: 'screen and (min-width: 768px)',
	md-down: 'screen and (max-width: 1023px)',
	lg-up: 'screen and (min-width: 1024px)',
	lg-down: 'screen and (max-width: 1367px)'
);
```

## Usage

Here is how to use `media()` mixin:

```scss
.b-block {
	// styles outside of a media query

	@include media(sm) {
		// styles for "s" viewports
	};

	@include media(md-up) {
		// styles for "m" and "l" viewports
	};
}
```

Simply edit this file and add your own media queries to `$media` map.

*/
/*md
@no-stat

# Palette

This is palette settings for project/brand. It divided into 2 main categories palette and applied color.

* Palette is general set of colors. It could be used directly if you do not have themes.
* Applied colors designed as layer of abstraction to have ability to overwritten on brand level.

*/
/*md
@no-stat

# Globals variables

This variables are set of different global settings that is used across sets of components.

It include:

* globals
* depth of components (box-shadow)
* motion of components

*/
/*md
@no-stat

# Rh (Indents rhythm)

This function is designed to keep consistency of vertical and horizontal indents in the project.

Not all values are identical in design, sometimes 20px become 21px, 19px, 22px etc. It does not make
any sense to implement it as is. To keep indents consistent we need to round this values to 4px steps.

Ex: in design 22px / 19px -> rh(5) => 20px; in design 23px -> rh(6) => 24px etc.

This is alternate approach to `$space-md: 10px; $space-lg: 20px;`.

Designers should use the rhythm in his work.

## Usage

```scss
.component {
	@include rh(2); // => 8px
	@include rh(2 4 0); // => 8px 16px 0
}
```
*/
/*md
@no-stat

# Z-indexes

Z-index is an inherently tricky thing, and maintaining z-index order in a complex layout is difficult.
With different stacking orders and contexts, keeping track of them as their numbers increase can be hard.
<br />
<br />
We use sass function to help manage z-indexes from single place.
The most important requirement of this technique is sticking to it.
Any rogue hard-coded z-index values could compromise the integrity of those derived from your list.

## Usage

**We don't use hardcoded integer `z-index` values. Instead, we use indexes from the map `$z-indexes`**

### 1. Set up `$z-indexes` map
```scss
$z-indexes: (
    components: (
        component_name: (),
        checkbox: (
            before: (),
            after: (),
            icon: (),
        )
    ),
    content: (),
    popup-menu: ()
);
```

### 2. Add values in SCSS classes using `z()` function

#### Global components
```scss
.b-components { z-index: z(components); }
.b-content { z-index: z(content); }
.b-pop_up-menu { z-index: z(popup-menu); }
```

#### Inside a component
```scss
.b-component_name { z-index: z(components, component_name); }
.b-checkbox {
    &-before { z-index: z(components, checkbox, before); }
    &-after { z-index: z(components, checkbox, after); }
    &-icon { z-index: z(components, checkbox, icon); }
}
```

### 3. Get resulting CSS
```scss
.b-components { z-index: 1; }
.b-content { z-index: 2; }
.b-pop_up-menu { z-index: 3; }

.b-component_name { z-index: 1; }
.b-checkbox-before { z-index: 1; }
.b-checkbox-after { z-index: 2; }
.b-checkbox-icon { z-index: 3; }
```

*/
/*md
@no-stat

# Grids

## How to setup grids config for project

### Several grid configs for project

We can use several grid configs per project. For that, we need to add a new grid name to the `$grids` map with settings.

### Gaps / margin / column span configuration:

```scss
$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	)
);
```

### Grid-span configuration

Please see details [grid-span](01-core-functions-grid-span.html)

## Work with grids

### Development approaches

#### 1. Using `g-grid` mixin

With features of `display: grid`. Please see [g-grid](02-components-g-grid.html) details.

#### 2. Using `grid-span` function

Could be used in conjunction with different display properties while maintaining their common features(floating, centering, etc.). Please see [grid-span](01-core-functions-grid-span.html) details.

### Get gaps / margin / column span

For that we have the next grid functions in `_grids_tools.scss`:
- grid-gutter
- grid-margin
- grid-columns

Please see [grid functions](00-configuration-grids_tools.html) details with usage examples.

### Examples of usage

Please see [ready-made tools](05-blocks-guide-l-cols.html) details.

*/
/*md
@no-stat

# grid-* (grid config get functions)

This functions designed to get parameters from grid configuration config and
use it for creating grids or reuse grid configuration into different components.

* `grid-gutter`
* `grid-columns`
* `grid-margin`

## Usage

```scss

// Configuration:

$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	),
	altered: (
		grid-columns: ('xl': 10,   'lg': 10,   'md': 10,   'sm': 6),
		grid-gutter:  ('xl': 10px, 'lg': 10px, 'md': 10px, 'sm': 10px),
		grid-margin:  ('xl': 40px, 'lg': 30px, 'md': 30px, 'sm': 20px),
	)
);

// Usage:

.component {
	padding: grid-gutter('lg'); // => grids -> 'default' -> grid-gutter -> lg = 20px
	padding: grid-gutter('lg', 'altered'); // => => grids -> 'altered' -> grid-gutter -> lg = 10px
}

.component-b {
	margin: grid-margin('lg');
	margin: grid-margin('lg', 'altered');

	@include media(sm) {
		margin: grid-margin('sm');
	}
}

.component-c {
	width: percentage(grid-columns('lg') / 4);

	@include media(sm) {
		width: percentage(grid-columns('sm') / 2);
	}
}
```
*/
/*md
@no-stat

# adjust-color-to-bg

This function used to adjust color depending on provided background color. It use
luminance human persived criteria as breakpoint for colors
[See more details](http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef).

It is especially useful for crating flexible themes.

## Arguments

```
$backgroundColor - bg color
$colorInverse - color if bg is dark. If not provided would return $color-white
$colorNormal - color if bg is light. If not provided would return $color-text

adjust-color-to-bg($backgroundColor, $colorInverse, $colorNormal)
```

## Usage

```scss
.component {
	color: adjust-color-to-bg($color-bg-header-line-1);

	// => results default 'white' if background dark
	// => results default 'black' if background is light
}

.component-custom-inverse-color {
	color: adjust-color-to-bg($color-bg-footer, grey);

	// => results 'grey' if background dark
	// => results default 'black' if background is light
}

.component-all-custom-colors {
	color: adjust-color-to-bg($color-bg-footer, green, red);

	// => result 'green' if background dark
	// => result 'red' if background is light
}
```

Based on Hugo Giraudel [work](https://css-tricks.com/snippets/sass/luminance-color-function/)
*/
/*md
@no-stat

# grid-span

`grid-span` function returns value which could be used as **width, max-witdth, flex-basis, etc.**

### Parameters
```scss
@function grid-span($column: 1, $break: 'lg', $with-gutter: false, $grid: 'default')
```

## Examples

### Flex-basis example

```scss
.b-grid {
	display: flex;

	.b-grid__item {
		flex-basis: grid-span($column: 3);
	}
}
```

### Floated items example

```scss
.b-grid {
	.b-grid__item {
		float: left;
		width: grid-span($column: 2);
	}
}
```

### Inline-block items example

```scss
.b-grid {
	.b-grid__item {
		display: inline-block;
		max-width: grid-span($column: 2);
	}
}
```

*/
/*md
@no-stat

# aspect-ratio

This function used to get percentage value of aspect ratio color to use in `padding` to
create container for images.

This technique used to prevent content bouncing during load and create layout shifts.

Calculation. 16:9 Aspect Ratio would result `(9 / 16) * 100 = 0.5625%`.

See proposed [specs](https://drafts.csswg.org/css-sizing-4/#aspect-ratio)

## Arguments

```
$width - width of element
$height - height of element

=> percentage

aspect-ratio($width, $height)
```

## Usage

```scss
.component {
	padding-bottom: aspect-ratio(16, 9);
	padding-bottom: aspect-ratio(1920, 1080);
	padding-bottom: aspect-ratio(1920px, 1080px);
}

.b-suggestions-item_image {
	@include g-image_container(_container, aspect-ratio(16, 9));

	img {
		@include g-image_container(_img);
	}
}
```

*/
/*md
@no-stat

# Hide

This mixin is especially useful for hiding text
or visually hide needed elements

Here is a list of parameters you can use:

* text - helps to hide text without loosing visibility for parsers
* visually - like for text but for the whole element

## Usage

```scss
.component {
	@include hide(visually);
}

.h-hide_vis {
	@include hide(visually, true);
}
```
*/
.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

/*md
@no-stat

# Hover-supported

This mixin is designed to address iOS standard behavior of first tap - hover,
second tap - click that is engaged when control has hover styles applied.

This is critical for functionality like back-top-button. If we apply hover styles as is.
It would be activated only after second tap.

If rules are wrapped into this media it applied only in case if device have fine
pointer mechanism. [See more info](https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer).

Please note about mixed input devices - touch screen + mouse + touchpad,
touchpad + trackpoint, touch screen + stylus ("apple pencil") etc. -
sometimes browser do not report it properly, so logic should be builded around
exclusions.

## Usage

```scss
.component {
	@include hover-supported {
		&:hover {
			// Hover styles that should not be applied to touch
		}
	};
}
```
*/
/*md
@no-stat

# RTL selector

This mixin is designed to alter styles for RTL languages.

It mostly needed for alter position:absolute left|right coords, but could be used
as facade for different selectors.

## Usage

```scss
.component {
	left: 0;
	@include rtl {
		left: initial;
		right: 0;
	};
}
```
*/
/* stylelint-disable selector-no-vendor-prefix */
/*md

# g-button

Designed to provide same styles of buttons across different components.
It is possible to use with `<button>` or `<a>` elements

## First type button

```html_example
<button type="submit" class="b-button">
	Sign in
</button>
```

## First type disabled button

```html_example
<button type="submit" class="b-button m-disabled">
	Sign in
</button>
```

## First type, full width button

```html_example
<button type="submit" class="b-button m-width_full">
	Sign in
</button>
```

## Second type button

```html_example
<button type="submit" class="b-button m-outline">
	Sign in
</button>
```

## Second type disabled button

```html_example
<button type="submit" class="b-button m-outline m-disabled">
	Sign in
</button>
```

## Second type, full width button

```html_example
<button type="submit" class="b-button m-outline m-width_full">
	Sign in
</button>
```

## Link button

```html_example
<button type="submit" class="b-button m-link">
	Sign in
</button>
```

## Medium height Link button

```html_example
<button type="submit" class="b-button m-link m-medium">
	Sign in
</button>
```

## Small height Link button

```html_example
<button type="submit" class="b-button m-link m-small">
	Sign in
</button>
```

*/
/*md

# g-button_iconed

Designed to provide same styles of buttons that contain only icon (without any text)
across different components without explicit CSS class.

It is used for header menubar icons, hamburger menu items and dialog close button.

```scss
.b-dialog {
	// ...
	&-close {
		@include g-button_iconed;
	}
}
```
*/
/*md

# g-radio

The component is generally used for choosing item which includes in the radio group.

## Usage

Only one g-radio in a given group can be selected at the same time.

If user selects one option in the list that other options come to unselected.

`g-radio` has states: unchecked, checked, hover, disabled and invalid.

## Unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-2" class="b-radio-input"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-2">Some text</label>
</div>
```

## Checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-3" class="b-radio-input" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-3">Some text</label>
</div>
```

## Disabled unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-4" class="b-radio-input" disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-4">Some text</label>
</div>
```

## Disabled checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-5" class="b-radio-input" checked disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-5">Some text</label>
</div>
```

## Invalid unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-6" class="b-radio-input m-invalid"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-6">Some text</label>
</div>
```

## Invalid checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-7" class="b-radio-input m-invalid" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-7">Some text</label>
</div>
```

## Customization by SCSS

Radio button styles that used in several component.

Designed to use same style of radio in different components
ex: b-radio, b-payment_option, b-shipping_option, b-stores etc.

It provide styles only for icon element based on input node.

```scss_example
.b-option_switch {
	// ...
	&-input {
		@include g-radio(_input);
	}

	&-icon {
		@include g-radio(_icon);

		.b-option_switch-input:active + & {
			@include g-radio(_icon, m-active);
		}

		.b-option_switch-input:hover + & {
			@include g-radio(_icon, m-hover);
		}

		.b-option_switch-input:checked + & {
			@include g-radio(_icon, m-checked);
		}

		.b-option_switch-input[disabled] + & {
			@include g-radio(_icon, m-disabled);
		}
	}
}
```
*/
/*md

# g-checkbox

This component allows user to choose between two mutually exclusive state (checked or unchecked).

## Usage

A `g-checkbox` is used for select or unselect action items.

It is always tied to `<label>` that describes two opposite states.

The component usually points to choose settings or preferences.

`g-checkbox` has states (unchecked, checked, hover, disabled and invalid).

## Unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-1" />
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-1">Some text</label>
</div>
```

## Checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-2" checked/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-2">Some text</label>
</div>
```

## Disabled unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-3" disabled/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-3">Some text</label>
</div>
```

## Disabled checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-4" checked disabled/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-4">Some text</label>
</div>
```

## Invalid unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-5"/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-5">Some text</label>
</div>
```

## Invalid checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-6" checked/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-6">Some text</label>
</div>

```

## Customization by SCSS

Checkbox styles that used in several component.

Designed to use same style of checkbox in different components without additional CSS class.
ex: `b-checkbox`, `b-refinement_checkbox`, `b-account_preference` etc.

It provide styles only for icon element based on SVG.

```scss
.b-refinement_checkbox {
	// ...
	&-icon {
		@include g-checkbox(_icon);

		.b-refinement_checkbox:active & {
			@include g-checkbox(_icon, m-active);
		}

		.b-refinement_checkbox.m-checked & {
			@include g-checkbox(_icon, m-checked);
		}

		.b-refinement_checkbox.m-disabled & {
			@include g-checkbox(_icon, m-disabled);
		}
	}
}
```
*/
/*md

# g-spinner

Global spinner component applied to different blocks that fetch data.

Designed to use same style of spinner in different components and on particular breakpoints.
Ex: b-minicart_popup, b-suggestions, b-plp_grid, b-product_details, b-cart etc.

```scss
.b-product_gallery {
	&.m-loading_long::before {
		@include g-spinner();
	}
	// ...
}
```
*/
/*md

# g-link

Designed to provide same styles of text-type links across different components.

## Usage

```scss
.component-link {
	@include g-link;
}

.component-link {
	@include g-link();
}
```

*/
/*md

# g-link_hamburger

Hamburger menu generic link that used in several component.

Designed to use same style of hamburger link in different components
ex: menu, account link, language switcher etc.

```scss
.b-menu {
	// ...
	&-item {
		@include media(sm) {
			@include g-link_hamburger;
		}
	}
}
```
*/
/*md

# g-icon_gradient

Designed to provide same styles of icon gradient across different components without
explicit CSS class.

```scss
.b-user_greeting {
	// ...
	&-icon {
		@include g-icon_gradient;
	}
}
```
*/
/*md

# g-image_container

This is global component designed to hold image in place and preventing from layout bouncing during page load.

It based on `padding-bottom` trick. `padding-bottom` and `padding-top` relative units are based
on parent element `width`. So if you had an element that is 500px wide, and padding-top of 100%,
the padding-top would be 500px. [More info](https://css-tricks.com/aspect-ratio-boxes/)

```scss
.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}

.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}
```

You could change aspect ration in mixin:

```scss
@include g-image_container(_container, 100%);   // 1:1
@include g-image_container(_container, 150%);   // 2:3
@include g-image_container(_container, 133%);   // 3:4
@include g-image_container(_container, 125%);   // 5:4
@include g-image_container(_container, 75%);    // 4:3
@include g-image_container(_container, 66.6%);  // 3:2
@include g-image_container(_container, 56.25%); // 16:9
```

But it is preferable to define only one aspect ration through all images and not change it.

*/
/*md

# g-snap_scroll

Snap scroll functionality applied to different cases.

Designed to use same snap scroll functionality in different components and on particular breakpoints.
Ex: b-carousel, b-product_gallery, .b-product_slider etc.

```scss
.b-product_gallery {
	&-thumbs_track {
		@include g-snap_scroll($direction: y);
	}
	// ...
}
```

[Snap scroll MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scroll_Snap)

*/
/*md

# g-dialog_backdrop

Dialog window backdrop styles that used in several component.

Designed to use same style of backdrop and logic in different components and on particular breakpoints.
Ex: b-dialog, b-menu_panel, b-refinements_panel, b-minicart_panel etc.

```scss
.b-menu_panel {
	@include media(sm) {
		@include g-dialog_backdrop;
	}
	// ...
}
```
*/
/*md

# g-section_holder

This is global component designed to hold some standalone section of the site
as it wrapped into main container.

It could be used not only for standalone blocks, but also for page layouts.

```scss
.b-section {
	background: green;

	&-inner {
		@include g-section_holder;
	}
}
```
*/
/*md

# g-section_holder_header

Since header is differs from other container (g-section_holder)
we need special component the same as `section_holder` but with different
`max-width` and `margin`s.

This is global component designed to hold header of the site as it wrapped into
main container.

This common designs it could be removed and changed to `section_holder`.

```scss
.l-header-inner {
	background: green;

	&-inner {
		@include g-section_holder_header;
	}
}
```
*/
/*md

# g-heading_*

Some basic simple typography applied to different UI components.

This covers only very basic cases and could be extended.

```scss
.b-cart_empty {
	// ...

	&-title {
		@include g-heading_1;

		margin-bottom: rh(8);
	}
}
```
*/
/*md

# g-accordion

Global accordion component

## Attributes

```
data-allow-toggle="true" - Flag that allow or dissallow toggle
data-open-first="true" - Flag that open first item
data-allow-multiple="true" - Flag that allow or dissallow multiple open items
```

## Simple accordion example

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="false"
    data-open-first="true"
    data-allow-multiple="false"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Accordion with multiple open items

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="true"
    data-open-first="false"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Init accordion on sm, md & lg devices

### Attributes

```
.b-accordion
	data-widget="accordion" - init for all viewports
	data-widget.sm="accordion" - init for sm viewports
	data-widget.sm.md="accordion" - init for sm & md viewports
	data-widget.sm.md.lg="accordion" - init for sm & md & lg viewports
.b-accordion-item
	data-widget="accordionItem" - init for all viewports
	data-widget.sm="accordionItem" - init for sm viewports
	data-widget.sm.md="accordionItem" - init for sm & md viewports
	data-widget.sm.md.lg="accordionItem" - init for sm & md & lg viewports
```

```html_example
<div
    data-id="descriptions"
    data-widget.sm.md.lg="accordion"
    data-allow-toggle="true"
    data-open-first="true"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Customization by SCSS

This implementation allow to use accordion for one vieport and any other component for rest viewports

```scss
.b-accordion {
	@include g-accordion;

	&-item {
		@include g-accordion(_item);
	}

	&-title {
		@include g-accordion(_control);
	}

	&-content {
		@include g-accordion(_content);

		&[aria-hidden='false'] {
			@include g-accordion(_content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(_content_inner);
	}
}
```
*/
/*md

# g-grid

Grid layout component based on CSS grid.

It is designed to easy use project defined grid into components where CSS grid is
applicable.

```scss
.b-grid {
	@include g-grid();

	.b-columns__item {
		@include media(lg-up) {
			grid-column: 2 / span 4;
			grid-row: 1 / 2;
		}

		@include media(md-down) {
			grid-column: grid-start / span 7;
		}
	}
}
```
*/
@media print {
  @page {
    margin: 8px 8px auto;
    padding: 16px 0;
    size: a4;
  }
  h1,
h2,
h3,
h4,
h5,
h6 {
    page-break-after: avoid;
  }

  .b-hide_print {
    display: none !important;
  }

  .b-header_utility,
.l-header,
.b-menu_panel,
.b-footer {
    display: none;
  }
}
/*md

# Normalize forms

This package address differences of default styling through all major browsers.

Best practice not include this package *globally* until we use HTML-tags for UI components.

This styles are based on N.Galaher [normalize.css](https://necolas.github.io/normalize.css/)

*/
button,
input,
select,
textarea {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

input[type=button], input[type=submit], input[type=reset] {
  -webkit-appearance: button;
}
input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}
input[type=checkbox] {
  vertical-align: baseline;
}

button[disabled],
input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: none;
  padding: 0;
}
button:-moz-focusring,
input:-moz-focusring {
  outline: none !important;
}

input::-webkit-inner-spin-button {
  display: none;
}

input::-ms-clear {
  display: none;
}

/*md
@no-stat

# global-animations

This component is designed to store all global animation and include it only once in single place
so all other components could reuse this animations.
*/
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animation-chevron-up {
  0% {
    transform: translateY(-50%) rotate(46deg);
    width: 10px;
  }
  50% {
    transform: translate(-20%, -50%) rotate(0deg);
    width: 8px;
  }
  100% {
    transform: translateY(-50%) rotate(-46deg);
    width: 10px;
  }
}
@keyframes animation-chevron-down {
  0% {
    transform: translateY(-50%) rotate(-46deg);
    width: 10px;
  }
  50% {
    transform: translate(-20%, -50%) rotate(0deg);
    width: 8px;
  }
  100% {
    transform: translateY(-50%) rotate(46deg);
    width: 10px;
  }
}
@keyframes thumbs-zoom {
  0% {
    transform: translateY(35px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-from-bottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes dialog-opening {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes hero-carousel-progress {
  from {
    stroke-dashoffset: 104;
  }
  to {
    stroke-dashoffset: 1;
  }
}
@keyframes heart-bit {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
}
@media (prefers-reduced-motion) {
  * {
    animation: none !important;
    transition: none !important;
  }
}
/*md

# b-button

Please see [g-button](./02-components-g-button.html) for details.

## Primary Default
```html_example
<button type="button" class="b-button">
	Default #1
</button>

<button type="button" class="b-button m-hover">
	On hover
</button>

<button type="button" class="b-button m-disabled">
	Disabled
</button>
```

```html_example
<button type="button" class="b-button m-outline">
	Default #1
</button>

<button type="button" class="b-button m-outline m-hover">
	On hover
</button>

<button type="button" class="b-button m-outline m-disabled">
	Disabled
</button>
```

### Recommended to use #3 and #4 when background darker than #808080

```html_example
<div style="background-color: #7b7b7b; padding: 15px 30px;">
	<button type="button" class="b-button m-dark_bg">
		Default #1
	</button>

	<button type="button" class="b-button m-hover m-dark_bg">
		On hover
	</button>

	<button type="button" class="b-button m-disabled m-dark_bg">
		Disabled
	</button>
</div>
```

```html_example
<div style="background-color: #7b7b7b; padding: 15px 30px;">
	<button type="button" class="b-button m-outline m-dark_bg">
		Default #1
	</button>

	<button type="button" class="b-button m-outline m-hover m-dark_bg">
		On hover
	</button>

	<button type="button" class="b-button m-outline m-disabled m-dark_bg">
		Disabled
	</button>
</div>
```

## Secondary

```html_example
<button type="button" class="b-button m-secondary">
	Default
</button>

<button type="button" class="b-button m-secondary m-hover">
	On hover
</button>

<button type="button" class="b-button m-secondary m-disabled">
	Disabled
</button>
```

## Links

```html_example
<button type="button" class="b-button m-link">
	<span class="b-button-text">Women</span>
</button>

<button type="button" class="b-button m-link m-disabled">
	<span class="b-button-text">Disabled link</span>
</button>
```

```html_example
<button type="button" class="b-button m-link m-type_2">
	<span class="b-button-text">Category</span>
</button>

<button type="button" class="b-button m-link m-type_2 m-disabled">
	<span class="b-button-text">Disabled link</span>
</button>
```

## Button iconed
```html_example
<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
    <symbol id="arrow-down" viewBox="0 0 10 6">
		<path fill="currentColor" d="M5 6c-.256 0-.512-.098-.707-.293l-4-4c-.39-.39-.39-1.023 0-1.414.391-.39 1.023-.39 1.414 0l3.305 3.305L8.305.418c.4-.383 1.03-.372 1.414.025.384.397.373 1.031-.024 1.414l-4 3.862C5.5 5.907 5.25 6 5 6"></path>
	</symbol>
</svg>

<button type="button" class="b-button m-icon m-round">
	<svg width="18" height="18" aria-hidden="true" focusable="false">
		<use href="#arrow-down"></use>
	</svg>
</button>
```

*/
.b-button {
  align-items: center;
  border: 1px solid;
  cursor: pointer;
  display: inline-flex;
  height: 48px;
  justify-content: center;
  max-width: 100%;
  text-decoration: none;
  text-transform: uppercase;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: background-color, color, border;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;
  background: #000001;
  border: none;
  border-radius: 0;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 10px 40px;
}
.b-button.d-none {
  display: none;
}
.b-button.visual-hidden {
  visibility: hidden;
}
@media not all and (pointer: coarse) {
  .b-button:hover, .b-button.m-hover {
    background: #262626;
    text-decoration: none;
    text-shadow: 0.5px 0 0 #000001, -0.5px 0 0 #000001;
  }
}
.b-button:disabled, .b-button.m-disabled {
  background: #757575;
  color: #f2f2f2;
  pointer-events: none;
}
.b-button.m-outline {
  background: transparent;
  border: 1px solid #000001;
  color: #000001;
}
.b-button.m-outline:disabled, .b-button.m-outline.m-disabled {
  border-color: #757575;
  color: #757575;
}
.b-button.m-dark_bg {
  background: #ffffff;
  color: #000001;
}
@media not all and (pointer: coarse) {
  .b-button.m-dark_bg:hover, .b-button.m-dark_bg.m-hover {
    background: #f2f2f2;
  }
}
.b-button.m-dark_bg:disabled, .b-button.m-dark_bg.m-disabled {
  background: #c5c5c5;
  color: #757575;
}
.b-button.m-dark_bg.m-outline {
  background: transparent;
  border-color: #ffffff;
  color: #ffffff;
}
@media not all and (pointer: coarse) {
  .b-button.m-dark_bg.m-outline:hover, .b-button.m-dark_bg.m-outline.m-hover {
    border-color: #f2f2f2;
  }
}
.b-button.m-dark_bg.m-outline:disabled, .b-button.m-dark_bg.m-outline.m-disabled {
  border-color: #c5c5c5;
  color: #c5c5c5;
}
.b-button.m-secondary {
  background: #cdb5a7;
  color: #000001;
}
@media not all and (pointer: coarse) {
  .b-button.m-secondary:hover, .b-button.m-secondary.m-hover {
    background: #dbcbc1;
  }
}
.b-button.m-secondary:disabled, .b-button.m-secondary.m-disabled {
  background: #f0e9e5;
  color: #757575;
}
.b-button.m-link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  background: transparent;
  border-color: transparent;
  color: inherit;
  font-size: 14px;
  padding: 0;
  text-transform: uppercase;
}
@media not all and (pointer: coarse) {
  .b-button.m-link:hover {
    text-decoration: underline;
  }
}
.b-button.m-link.m-type_2 {
  font-size: 16px;
  letter-spacing: normal;
  text-transform: none;
}
.b-button.m-link.m-type_2 .b-button-text::before {
  top: 100%;
}
.b-button.m-link:disabled, .b-button.m-link.m-disabled {
  color: #c5c5c5;
}
.b-button.m-icon {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 48px;
  justify-content: center;
  text-align: center;
  width: 48px;
}
.b-button.m-icon:hover {
  color: #000001;
}
@media not all and (pointer: coarse) {
  .b-button.m-icon svg {
    transform: translateY(0);
    transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  }
  .b-button.m-icon:hover svg, .b-button.m-icon.m-hover svg {
    transform: translateY(-4px);
  }
}
.b-button.m-paypal {
  background: #ffc439;
  color: #000001;
}
.b-button.m-paypal:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.05);
  text-shadow: none;
}
.b-button.m-round {
  border: 1px solid #000001;
  border-radius: 50%;
}
.b-button.m-small {
  height: 24px;
}
.b-button.m-medium {
  height: 38px;
}
.b-button.m-width_full {
  width: 100%;
}
.b-button.m-long_text {
  height: auto;
  min-height: 48px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  white-space: normal;
  word-break: break-word;
}

.b-back_to_top {
  cursor: pointer;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .b-back_to_top {
    align-items: center;
    bottom: 80px;
    color: #000001;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    line-height: 1.2;
    min-width: 48px;
    opacity: 0;
    pointer-events: auto;
    position: fixed;
    right: 50px;
    text-transform: uppercase;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
    transition-property: opacity, visibility, box-shadow;
    visibility: hidden;
    z-index: 2;
  }
  html[dir=rtl] .b-back_to_top {
    left: 50px;
    right: initial;
  }
  @media not all and (pointer: coarse) {
    .b-back_to_top:hover {
      text-decoration: none;
    }
    .b-back_to_top:hover svg {
      transform: translateY(-3.5px);
    }
  }
  .b-back_to_top svg {
    height: 7px;
    transition: all cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  }
  .b-back_to_top.m-showed {
    opacity: 1;
    visibility: visible;
  }
  .b-back_to_top-arrow {
    align-items: center;
    background: #ffffff;
    border: 1px solid #000001;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-bottom: 5px;
    width: 40px;
  }
  .b-back_to_top-svg_line {
    display: none;
  }
  .b-back_to_top-copy_small {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .b-back_to_top {
    margin: 0 auto;
    max-width: 1366px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    border: 1px solid #000001;
    color: #000001;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    letter-spacing: 1px;
    margin: 0 15px 30px;
    min-height: 48px;
    text-transform: uppercase;
    width: calc(100% - 30px);
  }
}
@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1023px) {
  .b-back_to_top {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) and (max-width: 767px) {
  .b-back_to_top {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 767px) {
  .b-back_to_top-copy_large {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .b-back_to_top-arrow {
    margin-right: 10px;
  }
}

/*md

# b-global_alerts

This alerts used to notify blind user about dynamic changes on the page. Ex: load more products,
filters applied, sorting applied etc. To meet basic a11y requirements.

It is recommended to not hide this alerts and make them visible - it ease of testing
and fix as soon as it broken.

This messages should not have prominent visual styles.

```html_example
<div class="b-global_alerts">
    <div
        class="b-global_alerts-item"
        role="alert"
    >
        Filters applied
    </div>
</div>
```

## What this alerts covers

This is middle level between page and components.

Please see more [info](https://confluence.ontrq.com/pages/viewpage.action?pageId=228864950)

## Visually hide alerts

It is possible to hide this alerts visually, but it is not recommended.

We got special modificator to do so - `m-visually_hidden`. Please see hideAccessibilityAlerts site pref to more info.

*/
.b-global_alerts {
  bottom: 20px;
  left: 50%;
  position: fixed;
  text-align: center;
  transform: translateX(-50%);
  z-index: 14;
}
.b-global_alerts.m-visually_hidden {
  pointer-events: none;
  visibility: hidden;
}
.b-global_alerts-item {
  animation: slide-from-bottom cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  background-color: #f2f2f2;
  border: 1px solid #c5c5c5;
  border-radius: 2px;
  color: #000001;
  cursor: default;
  display: inline-block;
  font-size: 12px;
  margin: 0 auto;
  max-width: 400px;
  min-width: 129px;
  padding: 6px 10px;
}
.b-global_alerts-item.m-error {
  background-color: #feeeee;
  visibility: visible;
}

/*md

# b-highlighter

Focus highlighter CSS part. It draw focus ring over focused element.

We have two types of focus highlighting. In case if custom highlighter is not initialized will be shown default outline highlighter.

### Default outline highlighter
```html_example
<br>
<div>
	<input class="b-input m-focused" type="text" placeholder="Default outline example" style="width:240px;">
</div>
<br>
```

### Custom highlighter
```html_example
<br>
<div style="position:relative;">
	<div class="b-highlighter_inited">
		<input class="b-input" type="text" placeholder="Custom highlighter example" style="width:240px;">
	</div>
	<div class="b-highlighter m-visible" style="top:-5px; left:-4px; width:241px; height:50px;"></div>
</div>
<br>
```
*/
.b-highlighter {
  border: 4px solid transparent;
  border-radius: 2px;
  box-shadow: 0 0 10px 0 #000001;
  box-sizing: content-box;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition-duration: 0.15s;
  transition-property: top, left, width, height, visibility, opacity;
  transition-timing-function: cubic-bezier(0, 0.2, 0, 1);
  visibility: hidden;
  z-index: 15;
}
.b-highlighter.m-visible {
  opacity: 1;
  visibility: visible;
}
.b-highlighter.m-hurry {
  transition-duration: 0.1s;
}

*:focus,
.m-focused {
  outline: 3px solid #000001 !important;
}

.b-highlighter_inited *:focus {
  outline: none !important;
}

.b-continue_shopping {
  align-items: center;
  display: inline-flex;
  text-decoration: none;
}
.b-continue_shopping-icon {
  color: #000001;
  margin-inline-end: 8px;
  transform: rotate(90deg);
}
.b-continue_shopping-text {
  color: #000001;
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
}
.b-continue_shopping:hover {
  text-decoration: none;
}
.b-continue_shopping:hover .b-continue_shopping-text {
  color: #000001;
  text-decoration: none;
}

/*md

# b-link_phone

Phone link with **tel:[number]** scheme

```html_example
<a href="tel:1-888-222-3380" class="b-link_phone">1-888-222-3380</a>
```
*/
.b-link_phone {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: underline;
  unicode-bidi: isolate;
  white-space: nowrap;
}
@media not all and (pointer: coarse) {
  .b-link_phone:hover {
    text-decoration: none;
  }
}

/*md

# b-link_email

Email link with **mailto:[email]** scheme

```html_example
<a href="mailto:customerservice@boilerplate.com" class="b-link_email">customerservice@boilerplate.com</a>
```
*/
.b-link_email {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: underline;
  white-space: nowrap;
}
@media not all and (pointer: coarse) {
  .b-link_email:hover {
    text-decoration: none;
  }
}

/*md

# b-dialog

### We have two types of dialog: *Usual dialog* and *Search suggestions dialog*

The usual dialog have different modifiers **m-*** which depend on the width of the dialog

```html_example
<div>
	<button class="b-button m-outline"
			type="button"
			data-widget="emitBusEvent"
			data-bus-event-type="dialog.show"
			data-event-click.prevent="emitBusEvent"
			data-modal-config="{&quot;content&quot;: &quot; &quot;}"
	>
		Show usual dialog
	</button>
	<button class="b-button m-outline" data-widget="emitBusEvent" data-bus-event-type="page.show.searchbox" data-event-click.prevent="emitBusEvent">
		Show search suggestions dialog
	</button>
</div>
<div data-widget="globalModal" data-disable-rendering="false">
    <div class="b-dialog" data-ref="container" hidden></div>
    <script type="template/mustache" data-ref="template">
        <div data-ref="container" class="b-dialog m-reset_password">
            <div class="b-dialog-window m-top_dialog" role="dialog" data-ref="dialog" aria-modal="true">
                <div class="b-dialog-header">
                    <h2 class="b-dialog-title" id="forget-password-form-title">Forgot password?</h2>
                    <div data-tau="forgot_password_close">
                        <button class="b-dialog-close" type="button" data-dismiss="modal" data-event-click.prevent="cancel">
                            <svg aria-hidden="true" width="18" height="18" viewBox="0 0 18 18" focusable="false">
                                <use href="#close"></use>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="b-dialog-body">
                    <p class="b-form_section">Don't worry - it's easily done! Just enter your email address below and we'll send you a link to reset your password.</p>
                    <form class="reset-password-form" action="" method="POST">
                        <div data-widget="inputEmail" class="b-form_section m-required">
                            <label class="b-form_section-label">Email</label>
                            <input type="email" class="b-input">
                        </div>
                        <div class="b-dialog-footer m-actions">
                            <button type="submit" class="b-button m-width_full">Reset password</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </script>
</div>
<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
    <symbol id="close">
        <path fill="currentColor" d="m0.515,0.515c0.39,-0.39 1.023,-0.39 1.414,0l7.071,7.07l7.071,-7.07c0.39,-0.39 1.024,-0.39 1.414,0c0.39,0.39 0.39,1.023 0,1.414l-7.07,7.071l7.07,7.071c0.39,0.39 0.39,1.024 0,1.414c-0.39,0.39 -1.023,0.39 -1.414,0l-7.071,-7.07l-7.071,7.07c-0.39,0.39 -1.024,0.39 -1.414,0c-0.39,-0.39 -0.39,-1.023 0,-1.414l7.07,-7.071l-7.07,-7.071c-0.39,-0.39 -0.39,-1.024 0,-1.414z"></path>
    </symbol>
</svg>

<div data-widget="searchBox" data-disable-rendering="true">
    <div data-ref="container" class="b-dialog m-search_suggestions" data-event-click.self="closeModal">
        <div class="b-search_dialog" data-ref="dialog" role="dialog">
            <div class="b-search_dialog-inner">
                <div class="b-search_dialog-inner_top">
                    <div class="b-search_dialog-inner_top_content">
                        <div class="b-search_dialog-form_wrap">
                            <form role="search" method="get" name="simpleSearch" class="b-search_input">
                                <button class="b-search_input-submit" type="submit">
                                    <svg width="23" height="23" viewBox="0 0 27 28" focusable="false">
                                        <path fill="none" fill-rule="evenodd" stroke="currentColor" stroke-width="2" d="M19.484 19.984c4.1-4.1 4.066-10.784-.078-14.927C15.263.913 8.58.879 4.48 4.979c-4.1 4.1-4.066 10.784.078 14.927 4.143 4.144 10.826 4.178 14.927.078zm-.567-.355l7.239 7.494"></path>
                                    </svg>
                                </button>
                                <input role="combobox" id="header-search-input" class="b-search_input-input" type="search" name="q" value="" placeholder="Search">
                            </form>
                            <button type="button" class="b-search_dialog-cancel" data-event-click="closeSearch">Cancel</button>
                        </div>
                    </div>
                </div>
                <div role="listbox" id="search-suggestions-list" class="b-suggestions m-active">
                    <div role="none" class="b-suggestions-inner">
                        <div role="none" class="b-suggestions-section m-content">
                            <div role="none" class="b-suggestions-title">Quick Links</div>
                            <a role="option" id="result-item-0" class="b-suggestions-option b-suggestions-link" href="#">Privacy Policy</a>
                            <a role="option" id="result-item-1" class="b-suggestions-option b-suggestions-link" href="#">Men</a>
                            <a role="option" id="result-item-2" class="b-suggestions-option b-suggestions-link" href="#">Women</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
```
*/
.b-dialog {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
  transition-property: visibility, background-color;
  visibility: hidden;
  z-index: 10;
  justify-content: center;
  padding: 40px 0;
}
.b-dialog.m-opened, .b-dialog.m-active {
  background-color: rgba(0, 0, 1, 0.4);
  overflow-y: scroll;
  visibility: visible;
}
.b-dialog-window {
  animation: dialog-opening cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.35);
  color: #000001;
  margin: auto;
  max-width: 960px;
  min-height: 90vh;
  padding: 0 48px 36px;
  position: relative;
  transform-origin: top center;
  width: auto;
  z-index: 10;
}
@media screen and (min-width: 1024px) {
  .b-dialog-window {
    margin: auto 32px;
  }
}
@media screen and (min-width: 768px) {
  .b-dialog-window {
    min-width: 600px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-dialog-window {
    max-width: calc(100% - 64px);
  }
}
@media screen and (max-width: 767px) {
  .b-dialog-window {
    max-width: calc(100% - 30px);
    min-width: 345px;
    padding: 0 16px 36px;
  }
}
.b-dialog-window.m-active {
  min-height: auto;
}
.b-dialog-window.m-widget-loading::before {
  animation: 1s linear infinite rotate;
  border: 0.375em solid #000001;
  border-left-color: #f2f2f2;
  border-radius: 50%;
  border-top-color: #f2f2f2;
  content: "";
  display: block;
  height: 3em;
  margin: auto;
  pointer-events: none;
  position: relative;
  text-indent: -9999em;
  width: 3em;
  left: 50%;
  margin: -1em 0 0 -1em;
  position: absolute;
  top: 50%;
}
.b-dialog-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  min-height: 40px;
  padding-top: 40px;
}
@media screen and (min-width: 1024px) {
  .b-dialog-header.m-hide_lg {
    display: none;
  }
}
.b-dialog-title {
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.b-dialog-title.m-small {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  width: 100%;
}
.b-dialog-title.m-visually_hidden {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  max-height: 1px;
  max-width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  text-transform: none;
}
.b-dialog-close {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 48px;
  justify-content: center;
  text-align: center;
  width: 48px;
  position: absolute;
  right: 4px;
  top: 40px;
}
.b-dialog-close:hover {
  color: #000001;
}
html[dir=rtl] .b-dialog-close {
  left: 0;
  right: initial;
}
.b-dialog-close svg {
  height: 16px;
  width: 16px;
}
.b-dialog-body {
  line-height: 1.71;
  margin-top: 8px;
}
.b-dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .b-dialog-footer {
    flex-direction: column;
  }
}
.b-dialog-footer button {
  font-size: 14px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .b-dialog-footer button + button {
    margin-inline-start: 8px;
  }
}
@media screen and (max-width: 767px) {
  .b-dialog-footer button + button {
    margin-top: 8px;
  }
}

.b-dialog.m-size_guide .b-dialog-window {
  min-height: 30vh;
  min-width: auto;
  width: 900px;
}

.b-dialog.m-quick_view .b-dialog-window {
  min-height: 80vh;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-dialog.m-quick_view .b-dialog-window {
    min-width: calc(100% - 64px);
  }
}
@media screen and (min-width: 1024px) {
  .b-dialog.m-quick_view .b-dialog-window {
    max-width: 100%;
    padding: 0 88px 48px 28px;
    width: 1264px;
  }
}
.b-dialog.m-quick_view .b-dialog-body {
  display: none;
}

.b-dialog.m-search_suggestions {
  align-items: flex-start;
  display: none;
  overflow-y: auto;
  padding: 0;
}
.b-dialog.m-search_suggestions.m-opened {
  display: block;
}
.b-dialog.m-search_suggestions::after {
  touch-action: none;
}

.b-dialog.m-reset_password .b-dialog-window {
  min-width: auto;
  width: 560px;
}

.b-dialog.m-delete_address .b-dialog-window {
  max-width: 100%;
  min-width: auto;
  width: 524px;
}

.b-dialog.m-store_pickup .b-dialog-window {
  min-height: 70vh;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-dialog.m-store_pickup .b-dialog-window {
    min-width: calc(100% - 64px);
  }
}
@media screen and (min-width: 1024px) {
  .b-dialog.m-store_pickup .b-dialog-window {
    max-width: 100%;
    padding: 0 40px 28px;
    width: 1264px;
  }
}

.b-dialog.m-countdown .b-dialog-window {
  min-height: auto;
}

/*md
# b-content_placeholder

Future implemented content placeholder. Needed only for not implemented features, like "Bazarvoice reviews will be here".

## Example

```html_example
<div class="b-content_placeholder">
	Bazaarvoice reviews will be here
</div>
```
*/
.b-content_placeholder {
  font-size: 39px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  align-items: center;
  background-color: #f2f2f2;
  color: #757575;
  display: flex;
  height: 70vh;
  justify-content: center;
}

/*md

# b-user_content

This component is used to provide regular document styling in places where content managment team could
insert rich HTML markup.

## Headings

```html_example
<div class="b-user_content">
	<h1>General Information</h1>
	<h2>General Information</h2>
	<h3>General Information</h3>
	<h4>General Information</h4>
	<h5>General Information</h5>
</div>
```

## Paragraphs

```html_example
<div class="b-user_content">
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
</div>
```

## An unordered list

```html_example
<div class="b-user_content">
	<ul>
		<li>Provide, operate, and maintain our webste</li>
		<li>Improve, personalize, and expand our webste</li>
		<li>Understand and analyze how you use our webste</li>
	</ul>
</div>
```

## An ordered list

```html_example
<div class="b-user_content">
	<ol>
		<li>Develop new products, services, features, and functionality</li>
		<li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes</li>
		<li>Send you emails</li>
		<li>Find and prevent fraud</li>
	</ol>
</div>
```

## Full page

```html_example
<div class="b-user_content">
	<h1>
		Privacy Policy
	</h1>
	<h2>General Information</h2>
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
	<ul>
		<li>Provide, operate, and maintain our webste</li>
		<li>Improve, personalize, and expand our webste</li>
		<li>Understand and analyze how you use our webste</li>
	</ul>
	<ol>
		<li>Develop new products, services, features, and functionality</li>
		<li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes</li>
		<li>Send you emails</li>
		<li>Find and prevent fraud</li>
	</ol>
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
</div>
```
*/
.b-user_content > h1,
.b-user_content .b-h1 {
  font-size: 39px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 32px;
}
@media screen and (max-width: 767px) {
  html[lang=ru] .b-user_content > h1, html[lang=de] .b-user_content > h1,
html[lang=ru] .b-user_content .b-h1,
html[lang=de] .b-user_content .b-h1 {
    font-size: 24px;
  }
}
.b-user_content > h2,
.b-user_content .b-h2 {
  font-size: 27px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.b-user_content > h3,
.b-user_content .b-h3 {
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.b-user_content > h4,
.b-user_content .b-h4 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.b-user_content > h5,
.b-user_content .b-h5 {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.b-user_content > h2,
.b-user_content > h3,
.b-user_content > h4,
.b-user_content > h5 {
  margin-bottom: 16px;
  margin-top: 32px;
}
.b-user_content > h2:first-child,
.b-user_content > h3:first-child,
.b-user_content > h4:first-child,
.b-user_content > h5:first-child {
  margin-top: 0;
}
.b-user_content p {
  margin-bottom: 16px;
}
.b-user_content .b-paragraph {
  margin-bottom: 0;
}
.b-user_content .b-text_center {
  text-align: center;
}
.b-user_content .b-line_height_1 {
  line-height: 1;
}
.b-user_content ul,
.b-user_content ol {
  display: block;
  margin-bottom: 16px;
  padding-left: 20px;
}
.b-user_content li {
  display: list-item;
  margin-bottom: 4px;
}
.b-user_content ul {
  list-style: disc outside;
}
.b-user_content ol {
  list-style: decimal outside;
}
.b-user_content .b-unstyled_list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

/*md

# b-accordion

Please see [g-accordion](02-components-g-accordion.html) for details

*/
.b-accordion {
  box-shadow: 0 -1px 0 0 #d8d8d8;
  color: #000001;
}
.b-accordion-item {
  box-shadow: 0 1px 0 0 #d8d8d8;
}
.b-accordion-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 16px 9px 16px 0;
  text-align: start;
  text-transform: uppercase;
  width: 100%;
}
.b-accordion-button:hover {
  color: #000001;
}
.b-accordion-content {
  opacity: 0;
  overflow: hidden;
  position: relative;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  transition-property: height, opacity;
  visibility: hidden;
}
.b-accordion-content[aria-hidden=false] {
  opacity: 1;
  visibility: visible;
}
.b-accordion-item:not([data-initialized="1"]) .b-accordion-content {
  opacity: 1;
  visibility: visible;
}
.b-accordion-content_inner {
  overflow: hidden;
  padding: 0 16px 22px;
}

.b-icon_plus {
  display: block;
  flex-shrink: 0;
  height: 20px;
  margin-inline-start: auto;
  position: relative;
  width: 20px;
}
.b-icon_plus::after, .b-icon_plus::before {
  background-color: #000001;
  content: "";
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.b-icon_plus::before {
  height: 1px;
  width: 20px;
}
.b-icon_plus::after {
  display: block;
  height: 20px;
  width: 1px;
}
[aria-expanded=true] ~ .b-icon_plus::after, [aria-expanded=true] .b-icon_plus::after {
  display: none;
}

.b-icon_chevron {
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-inline-start: auto;
  position: relative;
  width: 16px;
}
.b-icon_chevron::after, .b-icon_chevron::before {
  animation-duration: 0.23s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  border-bottom: 2px solid;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  top: 50%;
  width: 9px;
}
.b-icon_chevron::before {
  animation-name: animation-chevron-down;
  border-bottom-left-radius: 1px;
  border-top-left-radius: 1px;
  left: 0;
}
[aria-expanded=true] ~ .b-icon_chevron::before, [aria-expanded=true] .b-icon_chevron::before {
  animation-name: animation-chevron-up;
}
.b-icon_chevron::after {
  animation-name: animation-chevron-up;
  border-bottom-right-radius: 1px;
  border-top-right-radius: 1px;
  right: 0;
}
[aria-expanded=true] ~ .b-icon_chevron::after, [aria-expanded=true] .b-icon_chevron::after {
  animation-name: animation-chevron-down;
}

/*md

# b-message

TBD

*/
.b-message {
  background-color: #f2f2f2;
  border: 1px solid #c5c5c5;
  border-radius: 2px;
  color: #000001;
  font-size: 12px;
  margin-bottom: 8px;
  padding: 6px 10px;
  text-align: center;
}
.b-message:last-child {
  margin: 0;
}
.b-message.m-success {
  background-color: #f2f2f2;
  color: #000001;
}
.b-message.m-error {
  background-color: #feeeee;
  border-color: #d54a4a;
  color: #000001;
}
.b-message.m-warning {
  background-color: #f2f2f2;
  border-color: #000001;
}
.b-message.m-order_confirmation {
  font-size: 16px;
  margin-top: 28px;
}
.b-message.m-info {
  display: flex;
  font-size: 14px;
  padding: 10px 20px;
  position: relative;
  text-align: left;
}
.b-message.m-disclaimer {
  margin-bottom: 20px;
}
.b-message-icon {
  border: 1px solid;
  border-radius: 50%;
  flex: 0 0 18px;
  height: 18px;
  line-height: 1;
  text-align: center;
}
.b-message-text {
  padding-left: 10px;
}
.b-message-link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
}
@media not all and (pointer: coarse) {
  .b-message-link:hover {
    text-decoration: underline;
  }
}
.b-message-link.m-highlighted {
  font-weight: 500;
}

/*md

# Input

Default input element

## Default

```html_example
	<input
		class="b-input"
		type="text"
		placeholder="Promo Code"
		name="couponCode"
		required=""
		value=""
	>
```

## Invalid

```html_example
	<input
		class="b-input m-invalid"
		type="text"
		placeholder="Promo Code"
		name="couponCode"
		required=""
		value=""
	>
```

*/
.b-input {
  appearance: none;
  border: 1px solid #000001;
  border-radius: 0;
  color: #000001;
  cursor: text;
  height: 48px;
  line-height: 48px;
  padding: 0 20px;
  vertical-align: baseline;
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .b-input {
    font-size: 16px;
  }
}
.b-input::-webkit-input-placeholder {
  font-size: 14px;
  color: #757575;
}
.b-input::-moz-placeholder {
  font-size: 14px;
  color: #757575;
}
.b-input:-ms-input-placeholder {
  font-size: 14px;
  color: #757575;
}
.b-input:-moz-placeholder {
  font-size: 14px;
  color: #757575;
}
.b-input.m-invalid {
  border: 1px solid #d54a4a;
}

/*md

# Select

This component allows user to choose one option from drop-down list.

<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
    <symbol id="arrow-down-small" viewBox="0 0 10 6">
        <path fill="currentColor" d="M5 6c-.256 0-.512-.098-.707-.293l-4-4c-.39-.39-.39-1.023 0-1.414.391-.39 1.023-.39 1.414 0l3.305 3.305L8.305.418c.4-.383 1.03-.372 1.414.025.384.397.373 1.031-.024 1.414l-4 3.862C5.5 5.907 5.25 6 5 6"></path>
    </symbol>
</svg>

```html_example
<div class="b-select">
	<select data-ref="field" class="b-select-input m-valid" id="" name="" required="" value="" maxlength="2147483647" aria-required="true" data-event-change="onChange" data-tau="" aria-describedby="" data-event-blur="validate">
		<option value="US" data-id="US" selected="">United States</option>
		<option value="CA" data-id="CA">Canada</option>
	</select>
	<svg aria-hidden="true" class="b-select-icon" width="10" height="6" focusable="false">
		<use href="#arrow-down-small"></use>
	</svg>
</div>
```

## Error State

```html_example
<div class="b-select">
	<select data-ref="field" class="b-select-input m-invalid" id="" name="" required="" value="" maxlength="2147483647" aria-required="true" data-event-change="onChange" data-tau="" aria-describedby="" data-event-blur="validate">
		<option value="" data-id="0">Please select</option>
		<option value="US" data-id="1">United States</option>
		<option value="CA" data-id="2">Canada</option>
	</select>
	<svg aria-hidden="true" class="b-select-icon" width="10" height="6" focusable="false">
		<use href="#arrow-down-small"></use>
	</svg>
</div>
```

*/
.b-select {
  position: relative;
  width: 100%;
}
.b-select-input {
  appearance: none;
  background: #ffffff;
  border: 1px solid #000001;
  color: inherit;
  cursor: pointer;
  font-weight: normal;
  height: 48px;
  line-height: 18px;
  outline: none;
  padding: 0 40px 0 14px;
  padding-inline-end: 40px;
  padding-inline-start: 14px;
  position: relative;
  user-select: none;
  width: 100%;
}
.b-select-input:focus::-ms-value {
  background: transparent;
  color: #000001;
}
.b-select-input::-ms-expand {
  display: none;
}
.b-select-input:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000001;
}
.b-select-input.m-invalid {
  background: #feeeee;
  border: 2px solid #d54a4a;
}
.b-select-input.m-disabled, .b-select-input[disabled] {
  border-color: #bababa;
  color: #bababa;
  cursor: default;
  pointer-events: none;
}
.b-select-input.m-disabled + svg, .b-select-input[disabled] + svg {
  color: #bababa;
}
.b-country_selector .b-select-input {
  padding-inline-start: 20px;
}
.b-select-input_as_text {
  appearance: none;
  background: none;
  border: none;
  max-width: 100%;
  pointer-events: none;
  white-space: normal;
}
.b-select-icon {
  bottom: 0;
  pointer-events: none;
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
}
html[dir=rtl] .b-select-icon {
  left: 14px;
  right: initial;
}

option {
  font-family: inherit;
  font-size: inherit;
}

.b-stepper {
  display: flex;
  user-select: none;
  width: 141px;
}
.b-stepper-button {
  border: 1px solid #bababa;
  cursor: pointer;
  display: block;
  line-height: 47px;
  margin: -1px;
  min-height: 47px;
  min-width: 47px;
  text-align: center;
  touch-action: manipulation;
}
.b-stepper-button:active {
  background-color: #000001;
  color: #ffffff;
  fill: currentColor;
}
.b-stepper-button[disabled] {
  background-color: #f2f2f2;
  border-color: #bababa;
  color: #bababa;
  pointer-events: none;
}
.b-stepper-input {
  appearance: none;
  border: 1px solid;
  border-color: #bababa #e0e0e0;
  border-radius: 0;
  box-shadow: none;
  font-size: 16px;
  margin: -1px;
  min-height: 47px;
  min-width: 47px;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;
}
.b-stepper-input:focus {
  border-color: #000001;
}
.b-stepper-input[disabled] {
  background-color: #f2f2f2;
  color: #757575;
  cursor: default;
  opacity: 1;
  -webkit-text-fill-color: #757575;
}

/*md

# Form section

Use this component to add some structure to forms.
It is possible to use any form element inside.

## Input element with label

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="input1">
			Enter Promo Code
		</label>
		<input class="b-input" type="text" id="input1" placeholder="Promo Code" name="couponCode" required="" value="">
	</div>
```

## Input element with required label

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="input2">
			<span class="b-form_section-required">*</span>
			Enter Promo Code
		</label>
		<input class="b-input" type="text" id="input2" placeholder="Promo Code" name="couponCode" required="" value="">
	</div>
```

## Input element with caption

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="input3">
			<span class="b-form_section-required">*</span>
			Enter Promo Code
		</label>
		<input class="b-input" type="text" id="input3" placeholder="Promo Code" name="couponCode" required="" value="">
		<span class="b-form_section-caption">
			Casing &amp; hyphens need to be exact
		</span>
	</div>
```

## Input element with error

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="input4">
			<span class="b-form_section-required">*</span>
			Enter Promo Code
		</label>
		<input class="b-input m-invalid" type="text" id="input4" placeholder="Promo Code" name="couponCode" required="" value="">
		<div role="alert" class="b-form_section-message">
			Coupon cannot be added to your cart
		</div>
		<span class="b-form_section-caption">
			Casing &amp; hyphens need to be exact
		</span>
	</div>
```

## Select element with label

```html_example
	<div class="b-form_section">
		<label class="b-form_section-label" for="select1">
			<span class="b-form_section-required">*</span>
			Country
		</label>
		<div class="b-select">
			<select class="b-select-input" id="select1" name="" required="" value="">
				<option value="US" data-id="US">United States</option>
				<option value="CA" data-id="CA">Canada</option>
			</select>
			<svg aria-hidden="true" class="b-select-icon" width="10" height="6" focusable="false">
				<path fill="currentColor" d="M5 6c-.256 0-.512-.098-.707-.293l-4-4c-.39-.39-.39-1.023 0-1.414.391-.39 1.023-.39 1.414 0l3.305 3.305L8.305.418c.4-.383 1.03-.372 1.414.025.384.397.373 1.031-.024 1.414l-4 3.862C5.5 5.907 5.25 6 5 6"></path>
			</svg>
		</div>
	</div>
```

## Checkbox element with label

```html_example
	<div class="b-form_section">
		<div class="b-checkbox">
			<input type="checkbox" class="b-checkbox-input" id="checkox1">
			<svg class="b-checkbox-icon" width="22" height="22" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
				<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
			</svg>
			<label class="b-form_section-label" for="checkox1">
				<span class="b-form_section-required">*</span>
					By signing up, you agree to <a href="#" target="_blank">Privacy and Cookie Policies</a>
			</label>
		</div>
	</div>
```

*/
.b-form_section.m-small {
  width: 250px;
}
.b-form_section.m-highlight {
  background: #f2f2f2;
  padding: 16px;
}
.b-form_section.m-fullwidth {
  width: 100%;
}
.b-form .b-form_section {
  margin-bottom: 30px;
}
.b-form .b-form_section.m-no_margin {
  margin-bottom: 0;
}
.b-form .b-form_section.m-topline {
  border-top: 1px solid #d8d8d8;
  margin-bottom: 20px;
  padding-top: 20px;
}
.b-form_section-label {
  display: flex;
}
.b-form_section-label.m-wai {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  max-height: 1px;
  max-width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
}
.b-checkbox .b-form_section-label {
  display: block;
}
.b-form_section.m-static_option .b-form_section-required {
  display: none;
}
.b-form_section-link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: underline;
  font-weight: 500;
}
@media not all and (pointer: coarse) {
  .b-form_section-link:hover {
    text-decoration: none;
  }
}
.b-form_section-email {
  font-weight: 700;
}
.b-form_section > .b-form_section-label {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 8px;
}
.b-form_section-caption {
  color: #757575;
  display: block;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}
.b-form_section-value {
  display: block;
  font-size: 16px;
  line-height: 1;
}
.b-form_section-counter {
  font-size: 12px;
  font-weight: 500;
  text-align: end;
}
.b-form_section-message {
  color: #d54a4a;
  display: block;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
}
.b-form_section-message::before {
  content: "*";
}
.b-form_section-message[hidden] {
  display: none;
}
.b-form_section input[type=date] {
  background-color: #ffffff;
}
.b-form_section input[type=date]::-webkit-input-placeholder {
  visibility: hidden !important;
}
.b-form_section input[type=date]::-webkit-datetime-edit-day-field:focus, .b-form_section input[type=date]::-webkit-datetime-edit-month-field:focus, .b-form_section input[type=date]::-webkit-datetime-edit-year-field:focus {
  background-color: #b4d7ff;
  outline: none;
}
.b-form_section input::-webkit-calendar-picker-indicator {
  display: none;
}

/*md

# b-checkbox

Please see [g-checkbox](02-components-g-checkbox.html) for details.

*/
.b-checkbox {
  color: #757575;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  user-select: none;
}
.b-checkbox-input {
  cursor: pointer;
  height: 24px;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 24px;
  z-index: 1;
}
html[dir=rtl] .b-checkbox-input {
  left: initial;
  right: 0;
}
.b-checkbox-icon {
  background-color: #ffffff;
  border: 1px solid #000001;
  border-radius: 0;
  cursor: pointer;
  margin-inline-end: 14px;
  min-width: 24px;
  position: relative;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: border-color;
  background: transparent;
  height: 24px;
  margin-top: 1px;
  padding: 3px;
  width: 24px;
}
.b-checkbox-icon path {
  stroke: #000001;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2.5px;
  transform: scale(0);
  transform-origin: center center;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: transform;
}
.b-checkbox-input:active + .b-checkbox-icon {
  transform: scale(0.9);
}
.b-checkbox:hover .b-checkbox-icon {
  border-color: #000001;
}
.b-checkbox-input:checked + .b-checkbox-icon {
  border: 2px solid #000001;
  border: 1px solid #000001;
}
.b-checkbox-input:checked + .b-checkbox-icon path {
  transform: scale(1);
}
.b-checkbox-input[disabled] + .b-checkbox-icon {
  background-color: #f2f2f2;
  border-color: #bababa;
  cursor: default;
  transform: scale(1);
}
.b-checkbox-input[disabled] + .b-checkbox-icon path {
  stroke: #757575;
}
.b-checkbox-input.m-invalid + .b-checkbox-icon {
  border-color: #d54a4a;
}
.b-checkbox-label {
  cursor: pointer;
}
.b-checkbox-input[disabled] + .b-checkbox-icon + .b-checkbox-label {
  color: #bababa;
  cursor: default;
}

.b-password_estimator {
  margin-top: 8px;
}
.b-password_estimator-inner {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.b-password_estimator-indicator {
  display: flex;
  padding: 8px 0;
}
.b-password_estimator-indicator_item {
  background-color: #d8d8d8;
  height: 3px;
  margin-inline-end: 4px;
  transition: background-color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  width: 35px;
}
@media screen and (min-width: 768px) {
  .b-password_estimator-indicator_item {
    width: 50px;
  }
}
.b-password_estimator-indicator_item:last-child {
  margin-inline-end: 0;
}
.b-password_estimator-indicator_item.m-weak {
  background-color: #d54a4a;
}
.b-password_estimator-indicator_item.m-medium {
  background-color: #f5a524;
}
.b-password_estimator-indicator_item.m-strong {
  background-color: #2f7f33;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-confirmation_create_account .b-password_estimator-indicator_item {
    width: 70px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-confirmation_create_account .b-password_estimator-indicator_item {
    width: 28px;
  }
}
@media screen and (min-width: 1367px) {
  .b-confirmation_create_account .b-password_estimator-indicator_item {
    width: 35px;
  }
}
.b-password_estimator-indicator_description {
  color: #757575;
  font-size: 12px;
  font-weight: 500;
}
.b-password_estimator-caption {
  color: #757575;
  font-weight: 500;
  margin-top: 4px;
}

.b-countdown {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}
.b-countdown-time {
  font-size: 39px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: absolute;
}
.b-countdown-icon {
  height: 190px;
  transform: rotateZ(-90deg);
  width: 190px;
}
.b-countdown-circle_bg {
  fill: none;
  stroke: #f2f2f2;
  stroke-width: 5px;
}
.b-countdown-circle_animated {
  fill: none;
  stroke: #000001;
  stroke-width: 5px;
  transition: stroke-dasharray 2.8s linear;
}

.b-minicart_panel-container {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
  transition-property: visibility, background-color;
  visibility: hidden;
  z-index: 10;
}
.b-minicart_panel-container.m-opened, .b-minicart_panel-container.m-active {
  background-color: rgba(0, 0, 1, 0.4);
  overflow-y: scroll;
  visibility: visible;
}
.b-minicart_panel-container.m-opened {
  overflow: hidden;
}

.b-minicart {
  background-color: #ffffff;
  bottom: 0;
  color: #000001;
  height: 100%;
  max-width: 496px;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition-property: transform;
  visibility: hidden;
  width: 80vw;
  z-index: 10;
}
html[dir=rtl] .b-minicart {
  left: 0;
  right: initial;
  transform: translateX(-100%);
}
.b-minicart.m-activated {
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
}
.b-minicart.m-active {
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.35);
  transform: translateX(0);
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
  visibility: visible;
}
html[dir=rtl] .b-minicart.m-active {
  transform: translateX(0);
}
.b-minicart.m-loading_long::before {
  animation: 1s linear infinite rotate;
  border: 0.375em solid #000001;
  border-left-color: #f2f2f2;
  border-radius: 50%;
  border-top-color: #f2f2f2;
  content: "";
  display: block;
  height: 3em;
  margin: auto;
  pointer-events: none;
  position: relative;
  text-indent: -9999em;
  width: 3em;
  left: 50%;
  margin: -1em 0 0 -1em;
  position: absolute;
  top: 50%;
}
.b-minicart-inner {
  padding: 32px 20px;
}
.b-minicart[aria-busy=true] .b-minicart-inner {
  opacity: 0.6;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}
.b-minicart-header {
  margin-bottom: 20px;
}
.b-minicart-messages_item {
  padding: 4px 8px;
}
.b-minicart-content {
  margin: 32px 0 0;
}
.b-minicart-title {
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.b-minicart-actions {
  display: flex;
  justify-content: space-between;
}
.b-minicart-actions .b-button {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}
.b-minicart-actions .b-button + .b-button {
  margin-inline-start: 8px;
}
.b-minicart-empty {
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 20%;
  text-align: center;
}
.b-minicart-empty_icon {
  align-items: center;
  background-image: linear-gradient(to bottom, rgba(0, 0, 1, 0.14), rgba(0, 0, 1, 0.34));
  border-radius: 100%;
  color: #000001;
  display: flex;
  flex-shrink: 0;
  height: 65px;
  justify-content: center;
  overflow: hidden;
  width: 65px;
  margin-bottom: 32px;
}
.b-minicart .return_refunds {
  border-bottom: 1px solid black;
  background-color: #f0e9e5;
  padding: 8px 20px;
  max-height: 35px;
  text-align: center;
}

.b-minicart_product {
  border-top: 1px solid #d8d8d8;
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;
}
.b-user_content .b-minicart_product.m-order_history {
  margin-bottom: 0;
}
.b-minicart_product:first-child {
  border-top: none;
}
.b-minicart_product.m-bonus {
  border-top: none;
}
.b-minicart_product.m-bonus::before {
  border-top: 1px dashed #d8d8d8;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 75%;
}
.b-minicart_product.m-cancel {
  display: flex;
}
.b-minicart_product-inner {
  display: grid;
  grid-template-areas: "msg . . ." "image details details remove" "image qty total total";
  grid-template-columns: 143px auto auto 48px;
  grid-template-rows: auto 1fr auto;
  position: relative;
  width: 100%;
}
.b-minicart_product.m-checkout .b-minicart_product-inner {
  grid-template-areas: "msg ." "image details" "image qty" "image total";
  grid-template-columns: 87px auto;
}
.b-order_details.m-guest .b-minicart_product-inner {
  grid-template-columns: 87px auto;
}
.b-minicart_product.m-order_history .b-minicart_product-inner {
  grid-template-areas: "msg ." "image details" "image qty" "image total" "image stepper";
  grid-template-columns: 112px auto;
  grid-template-rows: 1fr;
}
.b-minicart_product-msg {
  background-color: #feeeee;
  border-color: #d54a4a;
  color: #000001;
  grid-column: span 4;
  margin-bottom: 20px;
  padding: 4px 8px;
  text-align: center;
}
.b-minicart_product-msg.m-gift {
  background-color: #f2f2f2;
  border-color: #000001;
  margin-bottom: 8px;
  padding: 0;
  text-align: start;
}
.b-minicart_product.m-checkout .b-minicart_product-msg.m-gift {
  grid-column: span 2;
}
.b-minicart_product-link {
  display: block;
}
.b-minicart_product-image {
  grid-area: image;
}
.b-minicart_product-details {
  grid-area: details;
  padding-inline-start: 20px;
}
.b-minicart_product-picture {
  background: #f8f5f5;
  display: block;
  overflow: hidden;
  padding-bottom: 133.3333333333%;
  position: relative;
  width: 100%;
}
.b-minicart_product-picture img {
  bottom: 0;
  color: #f8f5f5;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-minicart_product-title {
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.b-minicart_product-title a {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  text-decoration: none;
}
@media not all and (pointer: coarse) {
  .b-minicart_product-title a:hover {
    text-decoration: underline;
  }
}
.b-minicart_product-availability {
  white-space: nowrap;
}
.b-minicart_product-availability .b-availability {
  padding-top: 20px;
}
.b-minicart_product-bundle_title {
  margin-bottom: 8px;
}
.b-minicart_product-bundle_item {
  margin-bottom: 4px;
}
.b-minicart_product-bundle_item:not(:last-child)::after {
  content: ",";
}
.b-minicart_product-attribute {
  margin-bottom: 4px;
  text-transform: capitalize;
  word-break: break-word;
}
.b-user_content .b-minicart_product-attribute {
  margin-bottom: 4px;
}
.b-minicart_product-price {
  margin-bottom: 4px;
}
.b-minicart_product.m-checkout .b-minicart_product-price_each, .b-minicart_product.m-order_history .b-minicart_product-price_each {
  display: inline-block;
  margin-inline-end: 4px;
}
.b-minicart_product-price_total {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  grid-area: total;
  justify-content: flex-end;
}
.b-minicart_product.m-summary .b-minicart_product-price_total {
  font-weight: 400;
}
.b-minicart_product.m-checkout .b-minicart_product-price_total, .b-minicart_product.m-order_history .b-minicart_product-price_total {
  display: inline-block;
  font-weight: 500;
  margin-top: 10px;
  padding-inline-start: 20px;
}
.b-minicart_product.m-order_history .b-minicart_product-price_total {
  margin-top: 4px;
}
.b-minicart_product-price_total .b-price {
  display: inline-block;
  text-align: end;
}
.b-minicart_product-price_total .b-price-item {
  display: block;
  margin-inline-end: 0;
}
.b-minicart_product-price_total .b-price-item:not(:first-child) {
  margin-inline-start: 8px;
}
.b-minicart_product-promotions {
  margin: 8px 0;
}
.b-minicart_product-info {
  grid-area: qty;
  margin-top: 12px;
  padding-inline-start: 20px;
}
.b-minicart_product-info.m-gift {
  margin-top: 32px;
}
.b-minicart_product-info .b-form_section-label {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  max-height: 1px;
  max-width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
}
.b-minicart_product.m-checkout .b-minicart_product-info, .b-minicart_product.m-order_history .b-minicart_product-info {
  margin-top: 0;
}
.b-minicart_product-qty {
  display: flex;
}
.b-minicart_product.m-order_history .b-minicart_product-qty_inline {
  margin-bottom: 0;
}
.b-minicart_product-stepper {
  grid-area: stepper;
  margin-top: 10px;
  padding-inline-start: 20px;
}
.b-minicart_product-remove {
  grid-area: remove;
  margin-top: -8px;
}
.b-minicart_product.m-checkout .b-minicart_product-remove {
  display: none;
}
.b-minicart_product-remove_btn {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 40px;
}
.b-minicart_product-remove_btn:hover {
  color: #000001;
}
.b-minicart_product-remove_btn svg {
  height: 14px;
  width: 14px;
}
.b-payment_total {
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .b-payment_total {
    display: none;
  }
}
.b-minicart .b-payment_total {
  display: table;
}
.b-payment_total-name, .b-payment_total-value {
  font-size: 16px;
  font-weight: 700;
  padding: 20px 0;
  text-align: start;
}
.b-payment_total-value {
  text-align: end;
  vertical-align: top;
}
.b-payment_total-tax {
  color: #757575;
  display: block;
  font-size: 12px;
  font-weight: 500;
}

.b-product_tile {
  position: relative;
}
.b-product_tile-top {
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
}
.b-product_tile-image_link {
  display: block;
}
.b-product_tile-image_link.m-not_available {
  opacity: 0.5;
}
.b-product_tile-image {
  background: #f8f5f5;
  display: block;
  overflow: hidden;
  padding-bottom: 133.3333333333%;
  position: relative;
  width: 100%;
}
.b-product_tile-image img,
.b-product_tile-image video {
  bottom: 0;
  color: #f8f5f5;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-product_tile-quick_view {
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  bottom: 0;
  color: black;
  font-weight: 500;
  left: 0;
  opacity: 0;
  position: absolute;
  text-transform: initial;
  transition-duration: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  transition-property: color, opacity;
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .b-product_tile-quick_view {
    display: none;
  }
}
.b-product_tile-quick_view:hover {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}
.b-product_tile-quick_view.m-product_set {
  pointer-events: none;
}
.b-product_tile-quick_view:focus, .b-product_tile-top:hover .b-product_tile-quick_view {
  opacity: 1;
}
.b-product_tile-no_available {
  background-color: #ffffff;
  border-radius: 4px;
  bottom: 10px;
  color: #dd3636;
  font-size: 12px;
  font-weight: 400;
  left: 10px;
  letter-spacing: 1px;
  line-height: 1.83;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .b-product_tile-no_available {
    font-size: 10px;
  }
}
.b-product_tile-title {
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 1.6;
  margin-bottom: 5px;
  padding-right: 4px;
  text-transform: uppercase;
  font-family: "Georgia", "DejaVu Serif", serif;
}
.b-product_tile-link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  color: #000001;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-transform: uppercase;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  word-break: break-word;
}
@media not all and (pointer: coarse) {
  .b-product_tile-link:hover {
    text-decoration: underline;
  }
}
.b-product_tile-price {
  margin-bottom: 10px;
}
.b-product_tile-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*md

# Product tile swatches

Swatch that used on product tile.

This is simplified version of swatch that use links to appropriate product variation.

```html_example
<div class="b-product_tile_swatches">
	<a class="b-product_tile_swatches-swatch m-selected" href="" title="Green color" data-attr-value="Green" data-tau-color-id="Green" data-tau="color_swatch_selected" style="background-color: #2e7d32"></a>
	<a class="b-product_tile_swatches-swatch " href="#" title="Black color" data-attr-value="Black" data-tau-color-id="Black" style="background-color: #000000"></a>
	<a class="b-product_tile_swatches-swatch " href="#" title="White color" data-attr-value="White" data-tau-color-id="White" style="background-color: #FFFFFF"></a>
	<a class="b-product_tile_swatches-leftover" href="#" title="Show all" aria-label="Show all" data-tau="product_color_showAll">+1</a>
</div>
```
*/
.b-product_tile_swatches {
  align-items: center;
  display: flex;
  margin: 10px 0;
}
.b-product_tile_swatches-swatch {
  background-size: cover;
  border: 1px solid transparent;
  border-radius: 50%;
  height: 24px;
  margin-right: 15px;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: box-shadow, border-color;
  width: 24px;
}
.b-product_tile_swatches-swatch:last-child {
  margin-right: 0;
}
.b-product_tile_swatches-swatch:hover, .b-product_tile_swatches-swatch.m-selected {
  border-color: #000001;
  box-shadow: inset 0 0 0 1px #ffffff;
}
.b-product_tile_swatches-swatch.m-outline {
  border-color: #757575;
}
.b-product_tile_swatches-swatch.m-outline:hover, .b-product_tile_swatches-swatch.m-outline.m-selected {
  box-shadow: inset 0 0 0 1px #c5c5c5;
}
.b-product_tile_swatches-leftover {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  text-decoration: none;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
@media not all and (pointer: coarse) {
  .b-product_tile_swatches-leftover:hover {
    text-decoration: underline;
  }
}

@media screen and (max-width: 1023px) {
  .b-product_tile_alt_view::before {
    background-color: #757575;
    transform: translateX(-8px);
  }
  .b-product_tile_alt_view::after {
    background-color: #d8d8d8;
    transform: translateX(8px);
  }
  .b-product_tile_alt_view::after, .b-product_tile_alt_view::before {
    border-radius: 100%;
    bottom: 8px;
    content: "";
    display: inline-block;
    height: 5px;
    left: 50%;
    pointer-events: none;
    position: absolute;
    width: 5px;
    z-index: 1;
  }
  .b-carousel .b-product_tile_alt_view::after, .b-carousel .b-product_tile_alt_view::before {
    display: none;
  }
  .b-carousel.b-carousel-pics-view .b-product_tile_alt_view::after, .b-carousel.b-carousel-pics-view .b-product_tile_alt_view::before {
    display: block;
  }
  .b-product_tile_alt_view.m-alt_active::before {
    background-color: #d8d8d8;
  }
  .b-product_tile_alt_view.m-alt_active::after {
    background-color: #757575;
  }
}
@media screen and (max-width: 1023px) {
  .b-product_tile_alt_view.m-disabled::after, .b-product_tile_alt_view.m-disabled::before {
    content: none;
  }
}
.b-product_tile_alt_view-track {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .b-product_tile_alt_view-track {
    display: flex;
    -ms-overflow-style: none;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
  }
  .b-product_tile_alt_view-track::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .b-product_tile_alt_view.m-disabled .b-product_tile_alt_view-track {
    overflow-x: hidden;
    scroll-snap-type: none;
    scrollbar-width: auto;
  }
}
.b-product_tile_alt_view-item {
  height: 100%;
}
@media screen and (max-width: 1023px) {
  .b-product_tile_alt_view-item {
    min-width: 100%;
    scroll-snap-align: start;
  }
}
@media screen and (min-width: 1024px) {
  .b-product_tile_alt_view-item.m-alt {
    background-color: #ffffff;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  }
}
@media screen and (max-width: 1023px) {
  .b-carousel .b-product_tile_alt_view-item.m-alt {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .b-carousel.b-carousel-pics-view .b-product_tile_alt_view-item.m-alt {
    display: block;
    padding-bottom: 133.3333333333%;
  }
}
@media screen and (min-width: 1024px) {
  .b-product_tile_alt_view:hover .b-product_tile_alt_view-item.m-alt {
    opacity: 1;
    z-index: 1;
  }
}

/*md

# Rating

Designed to provide same styles of rating across different components.

<svg height="0" width="0" style="position:absolute" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
    <!-- 0 0 16.476 15.677 shape from 0.0 -->
    <symbol id="star">
        <path d="m13.344 15.662-5.0953-2.6691-5.0873 2.6842 0.96393-5.6707-4.1249-4.0089 5.691-0.83558 2.538-5.1618 2.5533 5.1543 5.6935 0.81868-4.113 4.0211z"></path>
    </symbol>
    <!-- 0 0 16.476 15.677 shape from 0.0 -->
    <symbol id="star-half">
        <path class="b-rating-empty" d="m13.344 15.662-5.0953-2.6691-5.0873 2.6842 0.96393-5.6707-4.1249-4.0089 5.691-0.83558 2.538-5.1618 2.5533 5.1543 5.6935 0.81868-4.113 4.0211z"></path>
        <path class="b-rating-filled" d="m8.1348 0.19141-2.4434 4.9707-5.6914 0.83594 4.125 4.0078-0.96484 5.6719 4.9746-2.625v-12.861z"></path>
    </symbol>
</svg>

```html_example
<div class="b-rating" title="5 out of 5 Customer Rating">
	<svg class="b-rating-icon" width="100" height="20" viewBox="0 0 100 16" focusable="false">
		<use class="b-rating-filled" href="#star" y="0" x="0.0"></use>
		<use class="b-rating-filled" href="#star" y="0" x="20.0"></use>
		<use class="b-rating-filled" href="#star" y="0" x="40.0"></use>
		<use class="b-rating-filled" href="#star" y="0" x="60.0"></use>
		<use class="b-rating-filled" href="#star" y="0" x="80.0"></use>
	</svg>
	<span class="b-rating-number" data-tau="product_rating">(5)</span>
</div>
```

## Rating with link to product

```html_example
<div class="b-rating" title="3.2 out of 5 Customer Rating">
	<a class="b-rating-link" href="#" title="3.2 out of 5 Customer Rating" aria-label="3.2 out of 5 Customer Rating">
		<svg class="b-rating-icon" width="100" height="20" viewBox="0 0 100 16" focusable="false">
			<use class="b-rating-filled" href="#star" y="0" x="0.0"></use>
			<use class="b-rating-filled" href="#star" y="0" x="20.0"></use>
			<use class="b-rating-filled" href="#star" y="0" x="40.0"></use>
			<use href="#star-half" y="0" x="60.0"></use>
			<use class="b-rating-empty" href="#star" y="0" x="80.0"></use>
		</svg>
		<span class="b-rating-number" data-tau="product_rating">(3.2)</span>
	</a>
</div>
```
*/
.b-rating {
  display: flex;
}
.b-rating-link {
  text-decoration: none;
}
.b-rating-link:hover {
  text-decoration: none;
}
.b-rating-filled {
  fill: #f5a524;
}
.b-rating-empty {
  fill: #bababa;
}
.b-rating-number {
  font-weight: 600;
  unicode-bidi: isolate;
}

/*md

# b-price

Designed to provide same styles of pricing across different components without explicit CSS class.
`.b-price` inherit font-size from parent wrapper or general font-size

## Regular price

```html_example
<div class="b-price" data-tau-price="product_price">
	<span class="b-price-item" content="9.99" data-tau-price="new">
		$9.99
	</span>
</div>
```

## Sale price

```html_example
<div class="b-price" data-tau-price="product_price">
	<span class="b-price-to">Was</span>
	<span class="b-price-item m-old" content="11.99" data-tau-price="old">
		$11.99
	</span>
	<span class="b-price-to">, is</span>
	<span class="b-price-item" content="9.99" data-tau-price="new">
		$9.99
	</span>
</div>
```

## Price range

```html_example
<div class="b-price" data-tau-price="product_price">
	<span class="b-price-to">From</span>
	<span class="b-price-item" content="9.99" data-tau-price="new">
		$9.99
	</span>
	<span class="b-price-to">to</span>
	<span class="b-price-divider">-</span>
	<span class="b-price-item" content="11.99" data-tau-price="old">
		$11.99
	</span>
</div>
```

## Free price

```html_example
<div class="b-price" data-tau-price="product_price">
	<span class="b-price-item m-free" content="Free" data-tau-price="new">
		FREE
	</span>
</div>
```

*/
.b-price {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  position: relative;
}
.b-price-item {
  display: inline-block;
  margin-inline-end: 8px;
  white-space: nowrap;
}
.b-price-item:last-child {
  margin: 0;
}
.b-price-item.m-old {
  color: #757575;
  text-decoration: line-through;
}
.b-price-item.m-free {
  text-transform: uppercase;
}
.b-price-divider {
  margin-inline-end: 8px;
}
.b-carousel-track .b-price {
  font-size: 12px;
  letter-spacing: 0.5px;
}

/*md

# b-promotion

Designed to provide same styles of promotion across different components.

We do not support any HTML in promotion messages.

## Promotion with configured link

```html_example
<div class="b-promotion">
	<div class="b-promotion-message">
		Get 15% off for <a href="#">order</a>
	</div>
</div>
```

## Empty promotion

```html_example
<div class="b-promotion">
	<div class="b-promotion-message"></div>
</div>
```

## Promotion with open dialog button

```html_example
<div class="b-promotion">
	<div class="b-promotion-message">
		Get 15% off for order
	</div>
	<button
		class="b-promotion-details"
		type="button"
		data-widget="emitBusEvent"
		data-bus-event-type="dialog.show"
		data-event-click.prevent="emitBusEvent"
		data-tau="promotion_details_cta"
		data-initialized="1"
	>
		Details
	</button>
</div>
```

*/
.b-promotion {
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
  position: relative;
}
.b-promotion-message {
  background-color: #f0e9e5;
  border: 1px solid #cdb5a7;
  border-radius: 3px;
  color: #262626;
  font-size: 12px;
  padding: 3px 8px;
}
.b-promotion-message:empty {
  display: none;
}
.b-promotion-message a {
  color: inherit;
  text-decoration: underline;
}
.b-option_switch-promotion .b-promotion-message {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  color: #000001;
}
.b-promotion-details {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  margin-inline-start: 10px;
  text-decoration: underline;
}
@media not all and (pointer: coarse) {
  .b-promotion-details:hover {
    text-decoration: underline;
  }
}
.b-promotion-details:hover {
  text-decoration: none;
}

/*md

# b-availability

Designed to provide same styles of availability across different components.

```html_example
<div data-ref="container" class="b-availability m-instock" aria-label="Availability">
	<span class="b-availability-instock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd" stroke-width="2">
				<circle cx="10" cy="10" r="9" fill="currentColor" stroke="currentColor"></circle>
				<path stroke="white" d="M6 9.914L10 13 18 2"></path>
			</g>
		</svg>
	</span>

	<span class="b-availability-outofstock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd">
				<circle cx="10" cy="10" r="10" fill="currentColor"></circle>
				<path stroke="white" stroke-linejoin="round" stroke-width="2" d="M0 0L8 8M0 8L8 0" transform="translate(6 6)"></path>
			</g>
		</svg>
	</span>

	<div class="b-availability-status">
		In Stock
	</div>
</div>
```

```html_example
<div data-ref="container" class="b-availability m-outofstock" aria-label="Availability">
	<span class="b-availability-instock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd" stroke-width="2">
				<circle cx="10" cy="10" r="9" fill="currentColor" stroke="currentColor"></circle>
				<path stroke="white" d="M6 9.914L10 13 18 2"></path>
			</g>
		</svg>
	</span>

	<span class="b-availability-outofstock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd">
				<circle cx="10" cy="10" r="10" fill="currentColor"></circle>
				<path stroke="white" stroke-linejoin="round" stroke-width="2" d="M0 0L8 8M0 8L8 0" transform="translate(6 6)"></path>
			</g>
		</svg>
	</span>

	<div class="b-availability-status">
		Out Of Stock
	</div>
</div>
```

```html_example
<div data-ref="container" class="b-availability m-lowinstock" aria-label="Availability">
	<span class="b-availability-instock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd" stroke-width="2">
				<circle cx="10" cy="10" r="9" fill="currentColor" stroke="currentColor"></circle>
				<path stroke="white" d="M6 9.914L10 13 18 2"></path>
			</g>
		</svg>
	</span>

	<span class="b-availability-outofstock_icon">
		<svg width="20" height="20" viewBox="0 0 20 20" focusable="false">
			<g fill="none" fill-rule="evenodd">
				<circle cx="10" cy="10" r="10" fill="currentColor"></circle>
				<path stroke="white" stroke-linejoin="round" stroke-width="2" d="M0 0L8 8M0 8L8 0" transform="translate(6 6)"></path>
			</g>
		</svg>
	</span>

	<div class="b-availability-status">
		5 Items In Stock
	</div>
</div>
```

*/
.b-availability {
  align-items: center;
  color: #757575;
  display: flex;
  font-size: 14px;
}
.b-availability.m-instock {
  display: none;
}
.b-availability-status::before {
  background-color: #2f7f33;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 10px;
  margin-inline-end: 8px;
  width: 10px;
}
.b-availability.m-outofstock .b-availability-status::before {
  background-color: #d54a4a;
}
.b-availability.m-lowinstock .b-availability-status::before {
  background-color: #f5a524;
}
.b-availability.m-instoreonly .b-availability-status::before {
  background-color: #d54a4a;
}
.b-availability.m-backorder .b-availability-status::before {
  background-color: #f5a524;
}
.b-availability.m-preorder .b-availability-status::before {
  background-color: #000001;
}
.b-availability-message.m-select_options_first {
  color: #d54a4a;
}
.b-product_details-availability .b-availability {
  margin: 30px 0 20px;
}
.l-pdp.m-quick_view .b-product_details-availability .b-availability {
  margin: 0;
}
.l-pdp-main.m-bundle .b-availability {
  font-size: 14px;
}

.b-wishlist_button {
  align-items: center;
  cursor: pointer;
  display: flex;
  letter-spacing: 1px;
  margin: 0;
  text-transform: uppercase;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  transition-property: color, fill;
}
.b-wishlist_button:hover {
  color: #000001;
}
.b-wishlist_button svg {
  height: 18px;
  width: 24.6px;
  stroke: #757575;
}
.b-wishlist_button[aria-busy=true] {
  cursor: wait;
  pointer-events: none;
}
.b-wishlist_button[aria-pressed=true] {
  color: #000001;
}
.b-wishlist_button[aria-pressed=true] svg {
  stroke: #000001;
}
.b-wishlist_button-icon {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 40px;
}
.b-wishlist_button-icon:hover {
  color: #000001;
}
.b-wishlist_button-icon:hover {
  color: inherit;
}
.b-wishlist_button use {
  color: inherit;
  fill: none;
}
.b-wishlist_button[aria-pressed=true] use {
  color: #000001;
  fill: #000001;
  stroke: #000001;
}
.b-wishlist_button[aria-pressed=true].m-added svg {
  color: #000001;
  fill: #000001;
  stroke: #000001;
}

.b-wishlist_button.m-tile {
  stroke: #8F8F8F;
  z-index: 2;
}

.b-search_input {
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  position: relative;
  width: 100%;
}
.b-search_input-submit {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 48px;
  justify-content: center;
  text-align: center;
  width: 48px;
  color: #757575;
  width: auto;
}
.b-search_input-submit:hover {
  color: #000001;
}
@media screen and (max-width: 1023px) {
  .b-search_input-submit:hover, .b-search_input-submit:focus {
    background: none;
    color: #000001;
  }
}
.b-search_input-input {
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: #000001;
  height: 48px;
  line-height: 48px;
  padding: 0;
  padding-inline-start: 10px;
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .b-search_input-input {
    font-size: 16px;
  }
}
.b-search_input-input::-webkit-search-cancel-button, .b-search_input-input::-webkit-search-decoration, .b-search_input-input::-webkit-inner-spin-button {
  appearance: none;
}
.b-search_input-input::-ms-clear {
  display: none;
}
.b-search_input-input::placeholder {
  color: #757575;
}
.b-search_input-clear {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 48px;
  justify-content: center;
  text-align: center;
  width: 48px;
  opacity: 0;
  pointer-events: none;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}
.b-search_input-clear:hover {
  color: #000001;
}
.b-search_input-clear.m-visible {
  opacity: 1;
  pointer-events: all;
}

.b-search_dialog {
  height: 100%;
  width: 100%;
}
.b-search_dialog-inner {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
  width: 100%;
  z-index: 6;
}
.b-search_dialog-inner_top_content {
  margin: 0 auto;
  max-width: 1366px;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-search_dialog-inner_top_content {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-search_dialog-inner_top_content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 767px) {
  .b-search_dialog-inner_top_content {
    padding-right: 0;
  }
}
.b-search_dialog-form_wrap {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 10px 0 0;
  position: relative;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .b-search_dialog-form_wrap {
    margin: 40px 0 0;
    padding: 0;
    width: 50%;
  }
}
.b-search_dialog-cancel {
  cursor: pointer;
  height: 48px;
  letter-spacing: 1px;
  padding: 0 20px;
  text-transform: uppercase;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
@media screen and (max-width: 767px) {
  .b-search_dialog-cancel {
    padding-right: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .b-search_dialog-cancel {
    left: 100%;
    position: absolute;
  }
}
.b-search_dialog-cancel:focus, .b-search_dialog-cancel:hover {
  color: #000001;
}

.b-suggestions {
  background-color: #ffffff;
  overflow-y: auto;
  padding: 30px 0;
  position: relative;
  right: 0;
  width: 100%;
  z-index: 1;
}
@media screen and (min-width: 1024px) {
  .b-suggestions {
    padding: 40px 0;
  }
}
.b-suggestions-inner {
  margin: 0 auto;
  max-width: 1366px;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-suggestions-inner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-suggestions-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .b-suggestions-inner {
    align-items: center;
  }
}
.b-suggestions-inner.m-empty {
  align-items: center;
  color: #757575;
  display: flex;
  justify-content: center;
  min-height: 120px;
  padding: 0 8px;
  text-align: center;
}
.b-suggestions.m-loading .b-suggestions-inner {
  opacity: 0.3;
  pointer-events: none;
}
.b-suggestions-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
@media screen and (min-width: 1024px) {
  .b-suggestions-section {
    margin-bottom: 40px;
    padding: 0;
    width: 50%;
  }
}
.b-suggestions-section:last-of-type {
  margin-bottom: 0;
}
.b-suggestions-title {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.b-suggestions-option {
  border-radius: 0;
  display: block;
  margin-bottom: 16px;
  position: relative;
}
.b-suggestions-option:last-child {
  margin-bottom: 0;
}
.b-suggestions-link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  line-height: 1.14;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
@media not all and (pointer: coarse) {
  .b-suggestions-link:hover {
    text-decoration: underline;
  }
}
.b-suggestions-link.m-focused {
  color: #000001;
}
.b-suggestions-link .b-suggestions-link_text::before {
  opacity: 1;
}
.b-suggestions-message {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  max-height: 1px;
  max-width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
}
.b-suggestions-view_results {
  font-size: 14px;
  padding: 14px 40px;
}
@media screen and (max-width: 767px) {
  .b-suggestions-view_results {
    width: 100%;
  }
}
.b-suggestions-view_results.m-focused {
  background: #000001;
  color: #ffffff;
}

.b-suggestions_category {
  color: #000001;
  text-decoration: none;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
.b-suggestions_category-title {
  text-decoration: underline;
}
.b-suggestions_category:hover, .b-suggestions_category.m-focused {
  color: #000001;
  text-decoration: underline;
}
.b-suggestions_category-image {
  background: #f8f5f5;
  display: block;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
  border-radius: 0;
  height: 30px;
  margin-inline-end: 12px;
  max-height: 30px;
  max-width: 30px;
  min-width: 30px;
  padding: 0;
  width: 30px;
}
.b-suggestions_category-image img {
  bottom: 0;
  color: #f8f5f5;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-suggestions_category-parent_title {
  font-weight: normal;
}

.b-suggestions_guess {
  color: #000001;
  line-height: 1.14;
  margin-bottom: 0;
  text-decoration: none;
}
.b-suggestions_guess.m-focused, .b-suggestions_guess:hover {
  text-decoration: none;
}
.b-suggestions_guess-correction_wrap {
  font-weight: 700;
}
.b-suggestions_guess-correction {
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  unicode-bidi: isolate;
}
.b-suggestions_guess.m-focused .b-suggestions_guess-correction, .b-suggestions_guess:hover .b-suggestions_guess-correction {
  color: #000001;
}

.b-suggestions_product {
  color: #000001;
  display: flex;
  margin-bottom: 15px;
  text-decoration: none;
}
.b-suggestions_product:last-child {
  margin-bottom: 0;
}
.b-suggestions_product-picture_wrap {
  margin-inline-end: 10px;
}
.b-suggestions_product-picture {
  background: #f8f5f5;
  display: block;
  overflow: hidden;
  padding-bottom: 133.3333333333%;
  position: relative;
  width: 100%;
  border-radius: 0;
  max-width: 100%;
  width: 80px;
}
.b-suggestions_product-picture img {
  bottom: 0;
  color: #f8f5f5;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-suggestions_product-title {
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 4px;
  max-width: 300px;
  text-transform: uppercase;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
@media not all and (pointer: coarse) {
  .b-suggestions_product:hover .b-suggestions_product-title {
    text-decoration: underline;
  }
}
.b-suggestions_product:hover .b-suggestions_product-title, .b-suggestions_product.m-focused .b-suggestions_product-title {
  color: #000001;
}
.b-suggestions_product-price_info {
  margin-inline-end: 8px;
}
.b-suggestions_product-info {
  color: #262626;
}
.b-suggestions_product-info .b-price {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.83;
}

.b-header_track_order {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  display: flex;
  min-height: 48px;
  padding: 0 20px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  padding: 0 4px;
  text-transform: none;
}
@media not all and (pointer: coarse) {
  .b-header_track_order:hover {
    text-decoration: underline;
  }
}
.b-header_track_order-icon {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 40px;
}
.b-header_track_order-icon:hover {
  color: #000001;
}

.b-footer-region.m-1 {
  background-color: #f8f5f5;
  color: #000001;
  padding: 50px 0;
}
@media screen and (max-width: 767px) {
  .b-footer-region.m-1 {
    padding: 30px 0 20px;
  }
}
.b-footer-region.m-2 {
  background-color: #f8f5f5;
  color: rgba(0, 0, 1, 0.8);
}
.b-footer-inner {
  margin: 0 auto;
  max-width: 1366px;
  padding-left: 50px;
  padding-right: 50px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-footer-inner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-footer-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.b-footer-content {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .b-footer-content {
    flex-direction: column;
  }
}
.b-footer-security_shopping {
  color: #404040;
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 28px;
}
.b-footer-payment_methods {
  margin: 0 0 28px;
}
.b-footer a, .b-footer p, .b-footer span,
.b-footer h1, .b-footer h2, .b-footer h3, .b-footer h4, .b-footer h5, .b-footer h6 {
  font-family: "Georgia", "DejaVu Serif", serif;
}

.b-footer_nav {
  color: #000001;
}
@media screen and (min-width: 768px) {
  .b-footer_nav {
    border-top: 1px solid #d8d8d8;
    display: flex;
    padding: 48px 0 0;
  }
}
@media screen and (max-width: 767px) {
  .b-footer_nav {
    margin: 0 -15px;
  }
}
.b-footer_nav-wrapper {
  padding-left: 15px;
}
@media screen and (max-width: 767px) {
  .b-footer_nav-wrapper {
    padding: 30px 0 0;
  }
}
@media screen and (max-width: 767px) {
  .b-footer_nav-title {
    color: #000001;
  }
}
@media screen and (min-width: 768px) {
  .b-footer_nav-title {
    color: #000001;
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 20px;
    position: relative;
    text-transform: uppercase;
  }
  .b-footer_nav-title .b-icon_chevron {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .b-footer_nav-button {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 16px 9px 16px 0;
    text-align: start;
    text-transform: uppercase;
    width: 100%;
    border-top: 1px solid #d8d8d8;
    font-weight: 400;
    letter-spacing: 0.5px;
    padding: 18px 25px 18px 16px;
  }
}
@media screen and (min-width: 768px) {
  .b-footer_nav-button {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .b-footer_nav-heading {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .b-footer_nav-content {
    opacity: 0;
    overflow: hidden;
    position: relative;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
    transition-property: height, opacity;
    visibility: hidden;
  }
  .b-footer_nav-content[aria-hidden=false] {
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (max-width: 767px) {
  .b-footer_nav-list {
    overflow: hidden;
    padding: 0 16px 22px;
    padding: 4px 16px 23px;
  }
}
.b-footer_nav-item {
  display: block;
  margin-bottom: 11px;
}
.b-footer_nav-item:last-child {
  margin-bottom: 0;
}
.b-footer_nav-link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  padding: 4px 0;
}
@media not all and (pointer: coarse) {
  .b-footer_nav-link:hover {
    text-decoration: underline;
  }
}
@media screen and (min-width: 768px) {
  .b-footer_nav-column {
    flex-basis: 25%;
    padding-inline-end: 20px;
  }
}
@media screen and (max-width: 767px) {
  .b-footer_nav-column {
    box-shadow: 0 1px 0 0 #d8d8d8;
  }
}
@media screen and (min-width: 768px) {
  .b-footer_nav-find_store {
    flex-basis: 25%;
    margin-bottom: 30px;
    padding-top: 7px;
  }
}
@media screen and (max-width: 767px) {
  .b-footer_nav-find_store {
    margin-bottom: 30px;
    padding: 0 15px;
  }
  .b-footer_nav-find_store:last-child {
    border-top: 1px solid #f2f2f2;
  }
}
.b-footer_nav-find_store .b-find_store {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  font-weight: 400;
  height: auto;
  width: auto;
}
@media not all and (pointer: coarse) {
  .b-footer_nav-find_store .b-find_store:hover {
    text-decoration: underline;
  }
}
@media screen and (max-width: 767px) {
  .b-footer_nav-find_store .b-find_store {
    border: 1px solid #000001;
    display: block;
    height: auto;
    letter-spacing: 0.5px;
    padding: 13px 0 12px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }
}
.b-footer_nav-find_store svg {
  height: 18px;
  margin-right: 5px;
  width: 18px;
}
@media screen and (max-width: 767px) {
  .b-footer_nav-find_store svg {
    margin-right: 3px;
  }
}

.b-footer_info-inner {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .b-footer_info-inner {
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) {
  .b-footer_social {
    flex-basis: 33.3333333333%;
  }
}
@media screen and (max-width: 767px) {
  .b-footer_social .b-footer_nav-title {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 1.33;
    margin-bottom: 17px;
    text-align: center;
    text-transform: uppercase;
    display: block;
  }
}
.b-footer_social-items {
  display: flex;
  margin-top: -7px;
}
@media screen and (max-width: 767px) {
  .b-footer_social-items {
    justify-content: center;
    margin-top: 0;
  }
}
.b-footer_social-link {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 48px;
  justify-content: center;
  text-align: center;
  width: 48px;
  height: 20px;
  margin-right: 22px;
  width: 20px;
}
.b-footer_social-link:hover {
  color: #000001;
}
@media screen and (max-width: 767px) {
  .b-footer_social-link {
    height: 28px;
    margin-right: 30px;
    width: 28px;
  }
}
.b-footer_social-link:hover {
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
}
.b-footer_social-link:last-child {
  margin-right: 0;
}
.b-footer_social svg {
  fill: currentColor;
}
@media screen and (max-width: 767px) {
  .b-footer_social svg {
    height: 28px;
    width: 28px;
  }
}

.b-copyright {
  font-size: 14px;
  padding: 34px 0 30px;
  text-align: left;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .b-copyright {
    margin: 0 auto;
    max-width: 280px;
    padding: 30px 0 20px;
    text-align: center;
  }
}
.b-copyright.m-centered {
  padding: 24px 0 20px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .b-copyright-link {
    margin-inline-start: 16px;
  }
}
@media screen and (max-width: 767px) {
  .b-copyright-link {
    margin-inline-end: 16px;
  }
}

.b-newsletters {
  display: flex;
}
@media screen and (max-width: 767px) {
  .b-newsletters {
    display: block;
  }
}
.b-newsletters-caption {
  margin-right: 62px;
}
@media screen and (max-width: 767px) {
  .b-newsletters-caption {
    margin-right: 0;
  }
}
.b-newsletters-group_wrapper {
  width: 515px;
}
@media screen and (max-width: 767px) {
  .b-newsletters-group_wrapper {
    width: 100%;
  }
}
.b-newsletters-group {
  display: flex;
  margin-bottom: 15px;
  position: relative;
}
.b-newsletters-group[hidden] {
  display: none;
}
.b-newsletters-input_wrapper {
  flex-grow: 1;
  margin-right: 15px;
}
@media screen and (max-width: 767px) {
  .b-newsletters-input_wrapper {
    margin-right: 10px;
  }
}
.b-newsletters-input {
  appearance: none;
  background: transparent;
  border: none;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  font-family: inherit;
  height: 44px;
  line-height: 44px;
  padding: 10px 0;
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .b-newsletters-input {
    font-size: 16px;
  }
}
.b-newsletters-input::-ms-clear {
  display: none;
}
.b-newsletters-input::placeholder {
  color: #757575;
  opacity: 1;
}
.b-newsletters-input.m-invalid {
  border: none;
  border-bottom: 1px solid #d54a4a;
}
.b-newsletters-message_validation {
  color: #d54a4a;
  line-height: 1.2;
  margin-top: 8px;
}
.b-newsletters-message_success {
  color: #000001;
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .b-newsletters-message_success {
    text-align: center;
  }
}
.b-newsletters-message_success_announcement {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  max-height: 1px;
  max-width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
}
.b-newsletters-privacy .b-checkbox {
  align-items: flex-start;
}
.b-newsletters-privacy[data-ref=agreeToMarketing] .b-form_section-required, .b-newsletters-privacy[data-ref=agreeToPrivacy] .b-form_section-required {
  display: none;
}
.b-newsletters-privacy a {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
}
@media not all and (pointer: coarse) {
  .b-newsletters-privacy a:hover {
    text-decoration: underline;
  }
}
.b-newsletters-privacy a:hover {
  text-decoration: none;
}
.b-newsletters-submit {
  font-size: 14px;
  height: 44px;
  width: 135px;
}

.b-notification_dialogs {
  bottom: 0;
  color: #000001;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 12;
}

.b-notification_dialog {
  background: rgba(242, 242, 242, 0.95);
  color: #000001;
  padding: 16px 0;
}
.b-notification_dialog-inner {
  margin: 0 auto;
  max-width: 1366px;
  padding-left: 50px;
  padding-right: 50px;
  align-items: center;
  display: flex;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-notification_dialog-inner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-notification_dialog-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 767px) {
  .b-notification_dialog-inner {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.b-notification_dialog-title {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  max-height: 1px;
  max-width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
}
.b-notification_dialog-content {
  font-weight: 300;
  margin: 0 auto;
  max-width: 1000px;
}
@media screen and (max-width: 767px) {
  .b-notification_dialog-content {
    margin-bottom: 24px;
    width: 100%;
  }
}
.b-notification_dialog a {
  color: inherit;
  display: inline-block;
  text-decoration: underline;
  white-space: nowrap;
}
.b-notification_dialog a:hover, .b-notification_dialog a:focus {
  opacity: 0.8;
}
.b-notification_dialog-button {
  margin: 0 24px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-notification_dialog-button {
    margin-inline-end: 0;
  }
}
@media screen and (max-width: 767px) {
  .b-notification_dialog-button {
    margin: 0;
    white-space: normal;
    width: 49%;
  }
}
.b-notification_dialog-button.m-outline {
  border-color: #000001;
  color: #000001;
}
.b-notification_dialog-button.m-outline:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: #000001;
}
.b-notification_dialog-button + .b-notification_dialog-button {
  border: 0;
}
@media screen and (max-width: 1023px) {
  .b-notification_dialog-button + .b-notification_dialog-button {
    padding: 0 8px;
  }
}

.b-connection_monitor {
  margin-bottom: 0;
  position: relative;
}
@media screen and (max-width: 767px) {
  .b-connection_monitor {
    align-items: center;
    display: flex;
    padding: 12px 0;
  }
}
.b-connection_monitor-inner {
  margin: 0 auto;
  max-width: 1366px;
  padding-left: 50px;
  padding-right: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-connection_monitor-inner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-connection_monitor-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.b-connection_monitor-icon {
  flex-shrink: 0;
}
.b-connection_monitor-caption {
  font-weight: 700;
}
.b-connection_monitor-message {
  font-weight: 400;
  margin-inline-start: 12px;
  text-align: start;
}
.b-connection_monitor-btn {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 48px;
  justify-content: center;
  text-align: center;
  width: 48px;
}
.b-connection_monitor-btn:hover {
  color: #000001;
}
@media screen and (min-width: 768px) {
  .b-connection_monitor-btn {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.b-footer_top {
  display: flex;
  font-weight: 300;
  justify-content: space-between;
  line-height: 22px;
  padding: 48px 0 36px;
}
@media screen and (max-width: 767px) {
  .b-footer_top {
    flex-direction: column;
    padding: 36px 0 0;
  }
}
.b-footer_top-block {
  flex-basis: 25%;
  padding-inline-end: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-footer_top-block {
    flex-basis: 33.3333333333%;
  }
}
@media screen and (max-width: 767px) {
  .b-footer_top-block {
    margin: 0 0 28px;
    padding: 0;
  }
}
.b-footer_top-block:last-child {
  padding: 0;
}
.b-footer_top-title {
  color: #000001;
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 16px;
}

.b-footer_headline {
  padding-top: 6px;
}
@media screen and (max-width: 767px) {
  .b-footer_headline {
    padding-top: 0;
    text-align: center;
  }
}
.b-footer_headline-title {
  color: #000001;
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 0.7px;
  margin-bottom: 15px;
  text-transform: uppercase;
  display: block;
}

.b-cancellation_popup_footer {
  border-top: 1px solid #d8d8d8;
  padding-top: 20px;
}
.b-cancellation_popup_footer-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.b-cancellation_popup_footer-button {
  flex-basis: calc(50% - 5px);
}

.onetrust-pc-dark-filter,
#onetrust-pc-sdk.otPcCenter {
  z-index: 14 !important;
}

#onetrust-banner-sdk.otFlat {
  background-color: rgba(255, 255, 255, 0.8) !important;
  z-index: 12 !important;
}

:root {
  --page_visibility: visible;
}

/*md

# Apple pay button

Part of the styling of Apple pay button is implemented by Demandware. Those styles inserted throgh
isactivedatahead tag. [Please see](https://confluence.ontrq.com/display/ACDC/ApplePay+button+styling)

We overwrite Demandware styles and provide modern approach for button styling and localization
possibilities.

 */
.b-apple_pay {
  margin: 14px 0;
}
.b-apple_pay.m-disabled {
  opacity: 0.4;
  pointer-events: none;
}
.b-apple_pay-button.b-apple_pay-button, .b-apple_pay-button.b-apple_pay-button:hover, .b-apple_pay-button.b-apple_pay-button:active {
  background-size: 75% 52%;
  border-radius: 0;
  cursor: pointer;
  margin: 0;
}
@supports (-webkit-appearance: -apple-pay-button) {
  .b-apple_pay-button.b-apple_pay-button, .b-apple_pay-button.b-apple_pay-button:hover, .b-apple_pay-button.b-apple_pay-button:active {
    -webkit-appearance: -apple-pay-button;
    background: none;
    border: none;
    -apple-pay-button-style: white-outline;
  }
}
@supports (-webkit-appearance: -apple-pay-button) {
  .b-apple_pay-button.b-apple_pay-button::after {
    content: "";
  }
}
.b-apple_pay-button.dw-apple-pay-logo-white {
  -apple-pay-button-style: white;
}
.b-apple_pay-button.dw-apple-pay-logo-white.dw-apple-pay-border {
  -apple-pay-button-style: white-outline;
}
.b-apple_pay-button.m-checkout {
  margin-bottom: 22px;
}
@supports (-webkit-appearance: -apple-pay-button) {
  .b-apple_pay-button.m-checkout, .b-apple_pay-button.m-checkout:hover, .b-apple_pay-button.m-checkout:active {
    -apple-pay-button-type: order;
  }
}
@supports (-webkit-appearance: -apple-pay-button) {
  .b-apple_pay-button.m-pdp, .b-apple_pay-button.m-pdp:hover, .b-apple_pay-button.m-pdp:active {
    -apple-pay-button-style: white;
    -apple-pay-button-type: buy;
  }
}
.b-apple_pay.m-disabled .b-apple_pay-button {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
  color: #404040;
  pointer-events: none;
}
.b-apple_pay-description {
  margin: 24px 0 20px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-proceed_checkout .b-apple_pay {
    flex: 1;
    margin: 0;
  }
}

/*md

# Apple pay button

Part of the styling of Apple pay button is implemented by Demandware. Those styles inserted throgh
isactivedatahead tag. [Please see](https://confluence.ontrq.com/display/ACDC/ApplePay+button+styling)

We overwrite Demandware styles and provide modern approach for button styling and localization
possibilities.

 */
.b-apple_pay {
  margin: 14px 0;
}
.b-apple_pay.m-disabled {
  opacity: 0.4;
  pointer-events: none;
}
.b-apple_pay-button.b-apple_pay-button, .b-apple_pay-button.b-apple_pay-button:hover, .b-apple_pay-button.b-apple_pay-button:active {
  background-size: 75% 52%;
  border-radius: 0;
  cursor: pointer;
  margin: 0;
}
@supports (-webkit-appearance: -apple-pay-button) {
  .b-apple_pay-button.b-apple_pay-button, .b-apple_pay-button.b-apple_pay-button:hover, .b-apple_pay-button.b-apple_pay-button:active {
    -webkit-appearance: -apple-pay-button;
    background: none;
    border: none;
    -apple-pay-button-style: white-outline;
  }
}
@supports (-webkit-appearance: -apple-pay-button) {
  .b-apple_pay-button.b-apple_pay-button::after {
    content: "";
  }
}
.b-apple_pay-button.dw-apple-pay-logo-white {
  -apple-pay-button-style: white;
}
.b-apple_pay-button.dw-apple-pay-logo-white.dw-apple-pay-border {
  -apple-pay-button-style: white-outline;
}
.b-apple_pay-button.m-checkout {
  margin-bottom: 22px;
}
@supports (-webkit-appearance: -apple-pay-button) {
  .b-apple_pay-button.m-checkout, .b-apple_pay-button.m-checkout:hover, .b-apple_pay-button.m-checkout:active {
    -apple-pay-button-type: order;
  }
}
@supports (-webkit-appearance: -apple-pay-button) {
  .b-apple_pay-button.m-pdp, .b-apple_pay-button.m-pdp:hover, .b-apple_pay-button.m-pdp:active {
    -apple-pay-button-style: white;
    -apple-pay-button-type: buy;
  }
}
.b-apple_pay.m-disabled .b-apple_pay-button {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
  color: #404040;
  pointer-events: none;
}
.b-apple_pay-description {
  margin: 24px 0 20px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-proceed_checkout .b-apple_pay {
    flex: 1;
    margin: 0;
  }
}

.b-wishlist_button {
  align-items: center;
  cursor: pointer;
  display: flex;
  letter-spacing: 1px;
  margin: 0;
  text-transform: uppercase;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  transition-property: color, fill;
}
.b-wishlist_button:hover {
  color: #000001;
}
.b-wishlist_button svg {
  height: 18px;
  width: 24.6px;
  stroke: #757575;
}
.b-wishlist_button[aria-busy=true] {
  cursor: wait;
  pointer-events: none;
}
.b-wishlist_button[aria-pressed=true] {
  color: #000001;
}
.b-wishlist_button[aria-pressed=true] svg {
  stroke: #000001;
}
.b-wishlist_button-icon {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 40px;
}
.b-wishlist_button-icon:hover {
  color: #000001;
}
.b-wishlist_button-icon:hover {
  color: inherit;
}
.b-wishlist_button use {
  color: inherit;
  fill: none;
}
.b-wishlist_button[aria-pressed=true] use {
  color: #000001;
  fill: #000001;
  stroke: #000001;
}
.b-wishlist_button[aria-pressed=true].m-added svg {
  color: #000001;
  fill: #000001;
  stroke: #000001;
}

.b-wishlist_button.m-tile {
  stroke: #8F8F8F;
  z-index: 2;
}

@media screen and (min-width: 1024px) {
  .b-header_wishlist {
    align-items: center;
    appearance: none;
    background: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: 48px;
    justify-content: center;
    text-align: center;
    width: 48px;
  }
  .b-header_wishlist:hover {
    color: #000001;
  }
}
@media screen and (max-width: 1023px) {
  .b-header_wishlist {
    color: inherit;
    cursor: pointer;
    text-underline-offset: 4px;
    text-decoration: none;
    align-items: center;
    cursor: pointer;
    display: flex;
    min-height: 48px;
    padding: 0 20px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    box-shadow: none;
    font-size: 14px;
    padding: 0 4px;
    text-transform: none;
  }
  @media not all and (pointer: coarse) {
    .b-header_wishlist:hover {
      text-decoration: underline;
    }
  }
}
@media not all and (pointer: coarse) {
  .b-header_wishlist svg {
    transform: translateY(0);
    transition: transform cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  }
  .b-header_wishlist:hover svg, .b-header_wishlist.m-hover svg {
    transform: translateY(-4px);
  }
}
.b-header_wishlist-icon {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 40px;
  stroke: black;
}
.b-header_wishlist-icon:hover {
  color: #000001;
}
.b-header_wishlist.m-animated .b-header_wishlist-icon {
  animation: heart-bit ease-out 1s;
  animation-delay: 2s;
}
.b-header_wishlist-copy {
  display: none;
}
.b-menu_panel .b-header_wishlist-copy {
  display: block;
}