.b-back_to_top {
	cursor: pointer;
	text-decoration: none;

	@include media(md-up) {
		align-items: center;
		bottom: 80px;
		color: $color-black;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		font-size: 14px;
		font-weight: 400;
		justify-content: center;
		line-height: 1.2;
		min-width: 48px;
		opacity: 0;
		pointer-events: auto;
		position: fixed;
		right: 50px;
		text-transform: uppercase;
		transition: $motion-ease;
		transition-property: opacity, visibility, box-shadow;
		visibility: hidden;
		z-index: z(back-to-top);

		@include rtl {
			left: 50px;
			right: initial;
		}

		@include hover-supported {
			&:hover {
				text-decoration: none;

				svg {
					transform: translateY(-3.5px);
				}
			}
		}

		svg {
			height: 7px;
			transition: all $motion-ease;
		}

		&.m-showed {
			opacity: 1;
			visibility: visible;
		}

		&-arrow {
			align-items: center;
			background: $color-bg;
			border: 1px solid $color-black;
			border-radius: 50%;
			display: flex;
			height: 40px;
			justify-content: center;
			margin-bottom: 5px;
			width: 40px;
		}

		&-svg_line {
			display: none;
		}

		&-copy_small {
			display: none;
		}
	}

	@include media(sm) {
		@include g-section_holder;

		align-items: center;
		border: 1px solid $color-black;
		color: $color-black;
		display: flex;
		font-size: 14px;
		font-weight: 400;
		justify-content: center;
		letter-spacing: 1px;
		margin: 0 15px 30px;
		min-height: 48px;
		text-transform: uppercase;
		width: calc(100% - 30px);

		&-copy_large {
			display: none;
		}
	}

	&-arrow {
		@include media(sm) {
			margin-right: 10px;
		}
	}
}
